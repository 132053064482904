import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMotivoByParadaPlanejadaId } from "../../../API/Motivos";

export const MotivosDevolucao = (props) => {
  const [motivoDevolucao, setMotivoDevolucao] = useState(null);

  useEffect(() => {
    if(props.parada != null )
        {getMotivoByParadaPlanejadaId(props.parada.id).then((resp) => {
            setMotivoDevolucao(resp.data);
          });} 
  }, [props.parada]);

  return (
    <>
      <Typography component="p">
        {motivoDevolucao && (
          <>
            <b>Motivo(s) da devolução: </b>
            {motivoDevolucao.map((item, index) => (
              <span key={index}>
                {item.titulo}
                {index < motivoDevolucao.length - 1 && ", "}
              </span>
            ))}
          </>
        )}
      </Typography>
    </>
  );
};
