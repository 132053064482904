import {instance} from './instance/axios'

export async function getAll() {
    try {
      const response = await instance.get('/api/Locais');
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function post(registro) {
    try {
      
      const response = await instance.post('/api/Locais', registro);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function put(id, registro) {
    try {
      
      const response = await instance.put('/api/Locais/'+id, registro);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function getById(id) {
    try {
      const response = await instance.get('/api/Locais/'+id);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function deleteById(id) {
    try {
      const response = await instance.delete('/api/Locais/'+id);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  export async function addLocaisInRota(idRota, ids) {
    try {
      
      const response = await instance.put('/api/Locais/AddInRota/'+idRota, ids);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function getDescricaoByLocais(ids){
    try {
      const response = await instance.post('/api/Locais/GetByIds', ids);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }