import { Download } from "@mui/icons-material";
import MapIcon from '@mui/icons-material/Map';
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { saveAs } from "file-saver";

const ImageWithFallback = ({ src, alt, fallbackComponent, ...props }) => {
    const [hasError, setHasError] = useState(false);

    const handleDownload = () => {
        saveAs(src);
    };

    function openMap() {
        const baseUrl = "https://www.google.com/maps/search/"
        window.open(`${baseUrl}?api=1&query=${props.latitude}%2C${props.longitude}`, '_blank', 'noreferrer');
    }

    if (hasError) return fallbackComponent;

    return (
        <Box
            elevation={3}
            sx={{
                height: "100%",
                width: "100%"
            }}
        >
            <img
                src={src}
                alt={alt}
                onError={() => setHasError(true)}
                style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: 8
                }}
            />
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                }}
            >
                <Tooltip title="Abrir local no Google Maps">
                    <IconButton
                        onClick={() => { openMap() }}
                        sx={{
                            color: "white",
                            backgroundColor: "gray",
                            "&:hover": {
                                backgroundColor: "darkgray",
                                opacity: 0.9,
                            },
                        }}
                    >
                        <MapIcon size={18} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download">
                    <IconButton
                        onClick={handleDownload}
                        sx={{
                            color: "white",
                            backgroundColor: "gray",
                            "&:hover": {
                                backgroundColor: "darkgray",
                                opacity: 0.9,
                            },
                        }}
                    >
                        <Download size={18} />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Box>
    );
};

export default ImageWithFallback;