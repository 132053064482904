import { instance_ms_frota } from "../instance/axios_Frota";

export async function getAll(dataInicio, dataFim, veiculoIds) {
    try {
        const response = await instance_ms_frota.post(`/api/Frota/Abastecimento/GetAll`,
            {
                dataInicio,
                dataFim,
                veiculoIds
            });
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export async function getById(id) {
    try {
        const response = await instance_ms_frota.get(`/api/Frota/Abastecimento/${id}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}