
import { Avatar, Box, Card, CardContent, IconButton, Skeleton, Typography } from "@mui/material";

export default function DashboardCard({ backgroundColor, icon, title, info, fontSize = "20px" }) {
    return (
        <Card variant='outlined' style={{ height: '80px' }}>
            <CardContent
                sx={{
                    width: "100%",
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "left",
                    padding: '2px !important'
                }}>
                <IconButton
                    sx={{
                        margin: "0px 10px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                    }}>
                    <Avatar style={{ backgroundColor: backgroundColor }}>
                        {icon}
                    </Avatar>
                </IconButton>
                <Box>
                    <Box
                        sx={{
                            display: 'flex'
                        }}>
                        <Typography
                            color="textSecondary"
                            sx={{
                                fontSize: fontSize,
                            }}
                        >
                            {title}:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: fontSize,
                                marginLeft: '10px',
                                color: 'rgb(100 100 100)'
                            }}
                        >
                            {info === "" ? <Skeleton style={{ width: '50px' }} /> : info}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}