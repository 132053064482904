export const getFormatSegundosToString = (segundos) => {
    if (segundos == null)
        return "";
    if (segundos === "")
        return "";
    if (segundos === undefined)
        return "";

    var sec_num = parseInt(segundos, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds2 = sec_num - (hours * 3600) - (minutes * 60);

    var textoHour = '';
    if (hours > 0) { textoHour = hours + " h"; }
    var textoMin = '';
    if (minutes > 0) { textoMin = minutes + " min"; }
    var textoSec = '';
    if (seconds2 > 0) { textoSec = seconds2 + " s"; }

    var texto = textoSec;
    if (textoSec !== '' && (textoMin !== '' || textoHour !== ''))
        texto = " e " + texto;
    texto = textoMin + texto;
    if (textoMin !== '' && textoSec !== '' && textoHour !== '')
        texto = ", " + texto;
    if (textoMin === '' && textoSec !== '' && textoHour !== '')
        texto = " e " + texto;

    texto = textoHour + texto;


    return texto + " ";
}

export const getFormatMinutosToString = (minutos) => {
    if (minutos == null)
        return "";
    if (minutos === "")
        return "";
    if (minutos === undefined)
        return "";

    //var sec_num = parseInt(minutos);
    var hours = Math.floor(minutos / 60);
    var minutes = Math.floor(minutos - (hours * 60));

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }

    var texto = hours + ':' + minutes;
    return texto + " ";
}
export const getFormatMinutosToStringExt = (minutos) => {
    if (minutos == null)
        return "";
    if (minutos === "")
        return "";
    if (minutos === undefined)
        return "";

    //var sec_num = parseInt(minutos);
    var hours = Math.floor(minutos / 60);
    var minutes = Math.floor(minutos - (hours * 60));


    var texto = '';
    if (hours > 0) { texto = hours + " h "; }
    if (minutes > 0) { texto += (texto !== '' ? ' e ' : '') + minutes + " min"; }

    return texto;
}

export const getFormatDataToDataApi = (data) => {
    var it = new Date(data);
    var i = it.getFullYear() + '-' + ('00' + (it.getMonth() + 1)).slice(-2) + '-' +
        ('00' + it.getDate()).slice(-2) + 'T' +
        ('00' + it.getHours()).slice(-2) + ":" +
        ('00' + it.getMinutes()).slice(-2) + ":" +
        ('00' + it.getSeconds()).slice(-2);

    return i;
}

export const getTime = (hora) => {
    var time =('00' + hora.getHours()).slice(-2) + ':' + 
       ('00' + hora.getMinutes()).slice(-2);

       return time;
}
export const getFormatDataToDataApi_HoraMinuto = (data, hora, minuto) => {
    var it = new Date(data);
    var i = it.getFullYear() + '-' + ('00' + (it.getMonth() + 1)).slice(-2) + '-' +
        ('00' + it.getDate()).slice(-2) + 'T' +
        ('00' + hora).slice(-2) + ":" +
        ('00' + minuto).slice(-2) + ":" +
        ('00' + it.getSeconds()).slice(-2);

    return i;
}
export const getFormatDataTimeToString = (data) => {
    var it = new Date(data);

    var ano = it.getFullYear()
    var mes = ('00' + (it.getMonth() + 1)).slice(-2);
    var day = ('00' + it.getDate()).slice(-2);
    var hour = ('00' + it.getHours()).slice(-2);
    var minutes = ('00' + it.getMinutes()).slice(-2);
    //var seconds = ('00' + it.getSeconds()).slice(-2);

    if (day === "01" && mes === "01" && ano === 1)
        return "";

    if (day === "31" && mes === "12" && ano === 1969)
        return "";

    return day + "/" + mes + "/" + ano + " " + hour + ":" + minutes;
}

export const calcularDiferencaDatasParaString = (dataInicial, dataFinal) => {

    if (dataInicial === undefined || dataInicial == null || dataInicial === "0001-01-01T00:00:00" || dataFinal === undefined || dataFinal == null || dataFinal === "0001-01-01T00:00:00") {
        return "--:--:--";
    }

    dataInicial = new Date(dataInicial);
    dataFinal = new Date(dataFinal);



    let diferenca = Math.abs(dataFinal - dataInicial);

    let horas = Math.floor((diferenca % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)); // Calcula as horas
    let minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
    let segundos = Math.floor((diferenca % (1000 * 60)) / 1000);

    let diferencaFormatada;

    diferencaFormatada = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    return diferencaFormatada;
};

export const calcularDiferencaDatasParaString2 = (dataInicial, dataFinal) => {

    if (dataInicial === undefined || dataInicial == null || dataInicial === "0001-01-01T00:00:00" || dataFinal === undefined || dataFinal == null || dataFinal === "0001-01-01T00:00:00") {
        return "";
    }

    dataInicial = new Date(dataInicial);
    dataFinal = new Date(dataFinal);

    let diferencaMilissegundos = Math.abs(dataFinal - dataInicial);
    return getFormatSegundosToString(diferencaMilissegundos / 1000);
};



export const getFormatDataTimeToStringComAsSemAno = (data) => {
    var it = new Date(data);

    var ano = it.getFullYear()
    var mes = ('00' + (it.getMonth() + 1)).slice(-2);
    var day = ('00' + it.getDate()).slice(-2);
    var hour = ('00' + it.getHours()).slice(-2);
    var minutes = ('00' + it.getMinutes()).slice(-2);
    //var seconds = ('00' + it.getSeconds()).slice(-2);

    if (day === "01" && mes === "01" && ano === 1)
        return "";

    if (day === "31" && mes === "12" && ano === 1969)
        return "";

    return day + "/" + mes + " as " + hour + ":" + minutes;
}

export const getFormatTimeToString = (data) => {
    var it = new Date(data);
    var hour = ('00' + it.getHours()).slice(-2);
    var minutes = ('00' + it.getMinutes()).slice(-2);
    var seconds = ('00' + it.getSeconds()).slice(-2);

    return hour + ":" + minutes + ":" + seconds;
}
export const getFormatTimeHMToString = (data) => {
    if (data === undefined || data == null)
        return "--:--";

    var it = new Date(data);
    var hour = ('00' + it.getHours()).slice(-2);
    var minutes = ('00' + it.getMinutes()).slice(-2);
    //var seconds = ('00' + it.getSeconds()).slice(-2);

    if (hour === '00' && minutes === '00')
        return "--:--";

    return hour + ":" + minutes;
}

export const getFormatData2ToString = (data) => {
    var it = new Date(data);
    var ano = it.getFullYear()
    var mes = ('00' + (it.getMonth() + 1)).slice(-2);
    var day = ('00' + it.getDate()).slice(-2);

    return ano + mes + day;
}

export const getFormatDataToString = (data) => {
    var it = new Date(data);
    var ano = it.getFullYear()
    var mes = ('00' + (it.getMonth() + 1)).slice(-2);
    var day = ('00' + it.getDate()).slice(-2);

    return day + "/" + mes + "/" + ano;
}

export const getFormatDataToString2 = (data) => {
    var it = new Date(data);
    var ano = it.getFullYear()
    var mes = ('00' + (it.getMonth() + 1)).slice(-2);
    var day = ('00' + it.getDate()).slice(-2);

    return ano + "/" + mes + "/" + day;
}

export const getDiasSemanas = (dias) => {
    return <>{dias.sort().join(' . ')
        .replace('1', 'Seg')
        .replace('2', 'Ter')
        .replace('3', 'Qua')
        .replace('4', 'Qui')
        .replace('5', 'Sex')
        .replace('6', 'Sáb')
        .replace('0', 'Dom')}</>;
}

export const getDataInicioDataFim = (dataInicioStr, dataFimStr) => {
    const dataInicio = new Date(dataInicioStr);
    const dataFim = new Date(dataFimStr);

    if (dataInicio.getDay() === dataFim.getDay()) {
        return `${getFormatDataToString(dataInicioStr)} das ${getFormatTimeHMToString(dataInicioStr)} até ${getFormatTimeHMToString(dataFimStr)}`;
    }

    return `${getFormatDataToString(dataInicioStr)} ${getFormatTimeHMToString(dataInicioStr)} até ${getFormatDataToString(dataFimStr)} ${getFormatTimeHMToString(dataFimStr)}`;
}

export const formatarAnoDataComHoras = (data) => {
    let it = new Date(data);
    let minValue = new Date('0001-01-01T00:00:00');
    if (it === null || it === undefined || +it === +minValue)
        return '';

    let prevIn = getFormatDataTimeToString(it).toString().split("/");
    if (prevIn.length === 1) {
        return '';
    }

    let ano = prevIn[2].toString().slice(2);
    let formatada = prevIn[0] + "/" + prevIn[1] + "/" + ano + "h";
    return formatada;
}

export const formatarAnoData = (data) => {
    if (data === null || data === undefined)
        return "";

    let it = new Date(data);
    let minValue = new Date('0001-01-01T00:00:00');
    if (it === null || it === undefined || +it === +minValue)
        return "";

    let ano = it.getFullYear().toString().substring(2);
    let mes = ('00' + (it.getMonth() + 1)).slice(-2);
    let day = ('00' + it.getDate()).slice(-2);
    return `${day}/${mes}/${ano}`;
}
export const getFormatarDiaMesa = (data) => {
    if (data === null || data === undefined)
        return "";

    let it = new Date(data);
    let minValue = new Date('0001-01-01T00:00:00');
    if (it === null || it === undefined || +it === +minValue)
        return "";

    let mes = ('00' + (it.getMonth() + 1)).slice(-2);
    let day = ('00' + it.getDate()).slice(-2);
    return `${day}/${mes}`;
}

export const getFormatDataHora = (data) => {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = String(data.getFullYear()).slice(-2);
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');

    return `${dia}/${mes}/${ano} às ${horas}:${minutos}h`;
}