import { instance } from './instance/axios'

export async function getAll(filtroAtivo = null) {
  try {
    let query = ""
    if (filtroAtivo !== -1) {
      query = `?filtroAtivo=${filtroAtivo}`;
    }

    const response = await instance.get(`/api/Users${query}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function post(registro) 
{
  try {
    const response = await instance.post('/api/Users', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {

      return { result: 'permissionDenied', data:  error.response.data };
    } 
  else {
    console.error(error);
    throw error;
    }
  }
}
export async function put(id, registro) {
  try {
    const response = await instance.put('/api/Users/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {

      return { result: 'permissionDenied', data:  error.response.data };
    } 
  else {
    console.error(error);
    throw error;
    }
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Users/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteById(id) {
  try {
    const response = await instance.delete('/api/Users/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function loginByUserName(userName, senha) {
  try {
    const response = await instance.post('/api/Users/login', { userName: userName, password: senha })
    if (response.status === 200) {
      return response.data;
    }
    return response
  } catch (error) {
    //console.error(error);
    throw error;
  }
}
