import { instance_ms_forms } from "../instance/axios_Forms";

export async function geMidiatByResposta(respostaId) {
    try {
        const response = await instance_ms_forms.get(`/api/Midia/GetByResposta/${respostaId}`);
        return response.data;
    }
    catch (error) {
        return error;
    }
}