import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ImageWithFallback from './ImageWithFallback/ImageWithFallback ';
import ErrorIcon from '@mui/icons-material/Error';
import { GRAY } from '../../Utils/ColorsGrafos';

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                mx: "auto",
                textAlign: "center"
            }}
        >
            <ImageWithFallback
                src={images[currentIndex].url}
                latitude={images[currentIndex].latitude}
                longitude={images[currentIndex].longitude}
                alt={`Slide ${currentIndex}`}
                fallbackComponent={
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "80px"
                        }}
                    >
                        <ErrorIcon />
                        <Typography>
                            O tempo de exibição da imagem expirou. Recarregue a tela para visualizá-la novamente.
                        </Typography>
                    </Box>
                }
            />

            <IconButton
                onClick={prevSlide}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: 10,
                    zIndex: 10,
                    color: "white",
                    backgroundColor: "gray",
                    transform: "translateY(-50%)",
                    "&:hover": {
                        backgroundColor: "darkgray",
                        opacity: 0.9,
                    },
                }}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                onClick={nextSlide}
                sx={{
                    position: "absolute",
                    top: "50%",
                    right: 10,
                    zIndex: 10,
                    color: "white",
                    backgroundColor: "gray",
                    transform: "translateY(-50%)",
                    "&:hover": {
                        backgroundColor: "darkgray",
                        opacity: 0.9,
                    },
                }}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
            <Box
                sx={{
                    margin: "5px 0px",
                    display: "flex",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)"
                }}
            >
                {images.map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            backgroundColor: currentIndex === index ? "black" : GRAY,
                            mx: 0.5,
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};


export default Carousel;