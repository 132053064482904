import React, { useContext, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { RotasEditRoute } from '../../Router/Routing';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { PutTrancarMotorista} from '../../API/Rotas';
import { MonitoramentoContext } from '../../Context/MonitoramentoContext';
import { VERMELHO_FRACO} from '../../Utils/ColorsGrafos';
import { getUser } from "../../Router/auth";


 function Acoes(row) {
  const history = useHistory();
  const { setAtualizarRotas } = useContext(MonitoramentoContext);

  //Verifica se é diferente do Id padrão do motorista travado da Maestri
  const isMotoristaIdDifferent = row.motoristaId !== 1153;

  if (isMotoristaIdDifferent) {
    return (
      <>
        {getUser().empresaId === 173 ? (
          <Tooltip title={title("Travar")}>
            <IconButton aria-label="" size="small">
              <LockOpenIcon
                onClick={() => {
                  PutTrancarMotorista(row.id);
                  setAtualizarRotas(true);
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}

        <Tooltip title={title("Visualizar no mapa")}>
          <IconButton aria-label="delete" size="small">
            <MapIcon
              onClick={() => {
                history.push(RotasEditRoute + row.id + "?tab=mapa");
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return (
      <>
      {getUser().empresaId === 173 ? (
        <Tooltip title={title("Destravar a rota")}>
          <IconButton
            aria-label="delete"
            size="small"
            style={{ color: VERMELHO_FRACO }}
          >
            <LockIcon
              onClick={() => {
                PutTrancarMotorista(row.id);
                setAtualizarRotas(true);
              }}
            />
          </IconButton>
        </Tooltip>
        ) : (
            <></>
          )}
        <Tooltip title={title("Visualizar no mapa")}>
          <IconButton aria-label="delete" size="small">
            <MapIcon
              onClick={() => {
                history.push(RotasEditRoute + row.id + "?tab=mapa");
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

function title(text) {
    return <div >
        <Typography variant="subtitle1"><b>{text}</b></Typography>
    </div>
}

export default Acoes;

