import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getById } from '../../API/Regiao';
import { SubmissionError } from 'redux-form'
import { camposForm } from './Config';
import { getUser } from '../../Router/auth';
import { RegioesNewRoute } from '../../Router/Routing';
import { Roles } from "../../Utils/Roles";


class FormConfig extends Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;
        this.state = { alertSucess: false, loading: l, values: { ativo: true, regiaoRoteirizada: true }, camposForm: camposForm };    
    }

    create = async values => {
        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    update = async values => {
        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    limpar = () => {
        this.setState({ alertSucess: false });
    }

    async componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            getById(id).then((value) => {
                if (value !== undefined)
                    this.setState({ values: value });
                this.setState({ loading: false });
            });
        } else {
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    empresaId: getUser().empresaId
                }
            }));
        }
        var utilizaRegiaoRoterizada = getUser().role !== null && getUser().role; 
        if (utilizaRegiaoRoterizada !== Roles.Suporte) {
            var rotaRegiao = camposForm.find(e => e.name === "regiaoRoteirizada");
            rotaRegiao.size = 0; // Deixa invisivel            
        }
        
        this.setState({ camposForm: camposForm });
    }

    render() {
        let id = this.props.match.params.id;
        if (id) {
            return (
                <Form
                    title="Editar Região"
                    form="editRegiao"
                    fields={this.state.camposForm}
                    onSubmit={this.update}
                    LinkNew={RegioesNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    loading={this.state.loading}
                    limpar={this.limpar}>
                </Form>
            );
        } else {
            return (
                <Form
                    title="Novo Região"
                    form="newRegiao"
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    CanNotSave={true}
                    LinkNew={RegioesNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    limpar={this.limpar}>
                </Form>
            );
        }
    }
}

export default FormConfig