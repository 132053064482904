import { getUser } from "../../Router/auth";
import { EmpresaIds } from "../../Utils/Empresa";
import { Etiqueta001 } from "./components/Etiqueta001";
import { EtiquetaKrambeck } from "./components/EtiquetaKrambeck";

function EtiquetaManager({ documento, volume, volumeTotal }) {
    const empresaId = getUser().empresaId;
    let content;

    switch (empresaId) {
        case 173:
        case EmpresaIds.EmpresaDoPedro:
            content = <Etiqueta001
                documento={documento}
                volume={volume}
                volumeTotal={volumeTotal}
            />
            break;
        case EmpresaIds.Krambeck:
            content = <EtiquetaKrambeck
                documento={documento}
                volume={volume}
                volumeTotal={volumeTotal}
            />
            break;
        default:
            content = <div>Nenhuma etiqueta cadastrada</div>;
            break;
    }

    return (
        <>
            {content}
        </>
    );
}

export default EtiquetaManager;