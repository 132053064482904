import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../Components/Button/Button';
import Typography from '@material-ui/core/Typography';
import { formatarNumeroParaReais } from '../../Utils/Money';
import { makeStyles } from '@material-ui/core/styles';
import { getById as getByIdVeiculo } from '../../API/Veiculos';
import { getById as getByIdRota } from '../../API/Rotas';
import Tooltip from '@material-ui/core/Tooltip';
import MapSelectPoint from '../../Components/Map/MapSelectPoint';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { DialogSelectRota } from "../ParadaPlanejada/Componentes/DialogSelectRota";
import { getAll as getallByRota, deleteById } from '../../API/ParadaPlanejadas';
import { getAll, getPesoTotal } from '../../API/Entregas';
import { Box, CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    rootChech: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    root: {
        height: '100vh',
    },
    buttonProgress: {
        color: 'black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: 'black',
        position: 'absolute',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        height: '100%',
        //margin: theme.spacing(1),
        position: 'relative',
    },
}));

export default function VeiculoMaps(props) {
    const classes = useStyles();

    const [idsSelecionados, setidsSelecionados] = React.useState([]);
    const [tamanho, setTamnho] = React.useState(0);
    const [peso, setPeso] = React.useState(0);
    const [cubagem, setCubagem] = React.useState(0);
    const [volume, setVolume] = React.useState(0);
    const [valorFrete, setValorFrete] = React.useState(0);
    const [valorTotal, setValorTotal] = React.useState(0);
    const [loadingPeso, setLoadingPeso] = React.useState(false);
    const [inicioDate, setInicioDate] = React.useState(new Date());
    const [fimDate, setFimDate] = React.useState(new Date());
    const [tamanhoIcone, setTamanhoIcone] = React.useState(30);
    const [entregas, setEntregas] = React.useState([]);
    const [location, setLocation] = React.useState(null);
    const [loadingAdd, setLoadingAdd] = React.useState(false);
    const [capacidadeDeCargaQuilos, setCapacidadeDeCargaQuilos] = React.useState();
    const [totalPesoEntregas, setTotalPesoEntregas] = React.useState(0);
    const [totalPesoEntregasJaCarregado, setTotalPesoEntregasJaCarregado] = React.useState(0);
    const [openTransferir, setOpenTransferir] = React.useState(false);
    const [loadingEntregas, setLoadingEntregas] = React.useState(false);

    useEffect(() => {
        try {
            setFimDate(fimDate.setHours(23));
            setFimDate(fimDate.setMinutes(57));
        } catch (e) { }

        try {
            setInicioDate(inicioDate.setHours(0));
            setInicioDate(inicioDate.setMinutes(0));
            setInicioDate(inicioDate.setSeconds(0));
        } catch (e) { }

        atualizarMapa();
        atualizarVeiculo();
    }, []);

    const atualizarVeiculo = (event) => {
        getByIdRota(props.match.params.id).then((value) => {
            getByIdVeiculo(value.veiculoId).then((valueV) => {
                setCapacidadeDeCargaQuilos(valueV.capacidadeDeCargaQuilos);
            }).catch((e) => {
                console.log('Erro ao buscar veiculo');
                console.log(e);
            });

        }).catch((e) => {
            console.log('Erro ao buscar rota');
            console.log(e);
        });

        getAll(props.match.params.id).then(async (value) => {
            var ids = value.map(x => x.id);
            var total = await getPesoTotal(ids);
            setTotalPesoEntregasJaCarregado(total.data)

        }).catch((e) => {
            console.log('Erro ao buscar entregas da rota');
            console.log(e);
        });
    }

    const setCaculaPeso = async (ids) => {
        setLoadingPeso(true);
        var total = await getPesoTotal(ids);
        setTotalPesoEntregas(total.data)

        // Função para filtrar os objetos JSON com base nos IDs
        var entregasFiltradas = entregas.filter(obj => ids.includes(obj.id));

        var totalValor = entregasFiltradas.reduce((acc, obj) => acc += obj.valorTotal, 0);
        var totalPeso = entregasFiltradas.reduce((acc, obj) => acc += obj.pesoTotal, 0);
        var cubagemTotal = entregasFiltradas.reduce((acc, obj) => acc += obj.cubagem, 0);
        var volumeTotal = entregasFiltradas.reduce((acc, obj) => acc += obj.volumeTotal, 0);
        var totalValorFrete = entregasFiltradas.reduce((acc, obj) => acc += obj.valorFrete, 0);

        setPeso(totalPeso);
        setCubagem(cubagemTotal);
        setVolume(volumeTotal);
        setValorFrete(totalValorFrete);
        setValorTotal(totalValor);

        setLoadingPeso(false);
    }

    const atualizarMapa = (event) => {

        if (fimDate == null || inicioDate == null)
            alert('Precisa selecionar uma data!');
        else {

            setLoadingEntregas(true);
            setidsSelecionados([]);
            setTamnho(0);
            setPeso(0);
            setCubagem(0);
            setVolume(0);
            setValorFrete(0);
            setValorTotal(0);
            setTotalPesoEntregasJaCarregado(0);
            setTotalPesoEntregas(0);
            setEntregas([]);

            getallByRota(props.match.params.id)
                .then(async (value) => {
                    setEntregas([]);

                    if (value.data != null) {
                        var entregas = await getAll(props.match.params.id);
                        await value.data.forEach(async parada => {
                            if (parada.entregaId) {
                                var e = await entregas.find((element) => element.id === parada.entregaId)
                                parada.entrega = e;
                            }
                        });

                        setEntregas(value.data);
                        if (value.data.length > 0) {
                            setLocation([value.data[0].endereco.latitude, value.data[0].endereco.longitude])
                        }
                    }
                }).catch((e) => {
                    console.log('Erro ao buscar entregas');
                    console.log(e);
                }).finally(_ => {
                    setLoadingEntregas(false);
                });
        }
    };

    async function excluirClick(openSnackbar) {
        setLoadingAdd(true);
        for (let index = 0; index < idsSelecionados.length; index++) {
            try {
                const id = idsSelecionados[index];
                await deleteById(id);
            } catch (e) { }
        }
        openSnackbar('Paradas removidas da rota!');
        setLoadingAdd(false);
        atualizarMapa();
    }

    return (
        <Grid component="main" className={classes.root}>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={12} style={{ alignSelf: "center" }}>
                    <Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%' }} variant="h5" component="div">
                        <b> Filtre e selecione as paradas pelo mapa para transferir de rota</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                    {/* <KeyboardDateTimePicker
                        label="De"
                        format="dd/MM/yyyy HH:mm"
                        value={inicioDate}
                        onChange={setInicioDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    /> */}
                </Grid>
                <Grid item xs={12} lg={1}>
                    {/* <KeyboardDateTimePicker
                        label="Até"
                        format="dd/MM/yyyy HH:mm"
                        value={fimDate}
                        onChange={setFimDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    /> */}
                </Grid>
                <Grid item xs={12} lg={1}>
                    <div className={classes.wrapper}>
                        {/* <Button variant="outlined" color="primary" loading={loadingEntregas} disabled={loadingEntregas} style={{ width: '100%', height: '100%' }} onClick={atualizarMapa}>
                            filtrar
                        </Button> */}
                    </div>
                </Grid>
                <Grid item xs={12} lg={2} style={{ textAlign: "center", alignSelf: "center" }}>
                    {/* {!capacidadeDeCargaQuilos? <></>:
                        (loadingPeso? <CircularProgress size={24} className={classes.buttonProgress2} />:
                        (capacidadeDeCargaQuilos>=(totalPesoEntregas+totalPesoEntregasJaCarregado)? 
                            <>
                            
                            <Typography variant="h6" style={{ color: verdePastel }}>  Disponível: <b>{round(capacidadeDeCargaQuilos-(totalPesoEntregas+totalPesoEntregasJaCarregado))} Kg</b></Typography>
                            <div style={{ marginLeft: '25%', marginRight: "25%" }}>
                                <ProgressBar completed={(totalPesoEntregas+totalPesoEntregasJaCarregado)} maxCompleted={capacidadeDeCargaQuilos} customLabel={(Math.round(((totalPesoEntregas+totalPesoEntregasJaCarregado) / capacidadeDeCargaQuilos) * 100)) + " %"} height='20px' bgColor={verdePastel} labelSize="15px"></ProgressBar>
                            </div>
                        
                        </>
                        :
                        <>
                            <Typography variant="h6" style={{ color: vermelhoPastel }}>  Excedido: <b>{round((totalPesoEntregas+totalPesoEntregasJaCarregado)-capacidadeDeCargaQuilos)} Kg</b></Typography>
                            <div style={{ marginLeft: '25%', marginRight: "25%" }}>
                                <ProgressBar completed={(totalPesoEntregas+totalPesoEntregasJaCarregado)} maxCompleted={capacidadeDeCargaQuilos} customLabel={(Math.round(((totalPesoEntregas+totalPesoEntregasJaCarregado) / capacidadeDeCargaQuilos) * 100)) + " %"} height='20px' bgColor={vermelhoPastel} labelSize="15px"></ProgressBar>
                            </div>
                        </>)   
                        )
                    } */}
                </Grid>
                <Grid item xs={12} lg={3} style={{ textAlign: "center", alignSelf: "center" }}>
                    <>
                        {tamanho > 0 ? tamanho === 1 ?
                            <Typography variant="h6">  {tamanho} entrega selecionada</Typography> : <Typography variant="h6">  {tamanho} entregas selecionadas</Typography>
                            : <> </>}
                        {tamanho > 0 ?
                            <>
                                <Typography variant="body2"><b>Peso: </b> {peso.toFixed().toLocaleString('pt-BR')} Kg &emsp;<b>Cubagem: </b>{cubagem.toLocaleString('pt-BR')} m³ &emsp;<b>Volume: </b>{volume.toLocaleString('pt-BR')} Vol</Typography>
                                <Typography variant="body2"><b>Valor total da carga: </b>{formatarNumeroParaReais(valorTotal)} &emsp;&emsp;<b>Valor total do frete: </b>{formatarNumeroParaReais(valorFrete)}</Typography>
                            </>
                            : <></>}
                    </>

                </Grid>
                <Grid item xs={12} lg={2}>
                    {tamanho > 0 ?
                        <Tooltip title="Transferir paradas de rota" style={{ width: '100%', height: '100%' }}>
                            <SharedSnackbarConsumer>
                                {({ openSnackbar }) => (
                                    <Tooltip title="Transferir paradas de rota">
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <Button variant="contained"
                                                color="primary"
                                                startIcon={<SwapHorizIcon />}
                                                style={{ width: '100%', height: '100%' }}
                                                onClick={() => { setOpenTransferir(true); }} loading={loadingAdd}>
                                                Transferir
                                            </Button>
                                            <SharedSnackbarConsumer>
                                                {({ openSnackbar }) => (
                                                    <DialogSelectRota
                                                        open={openTransferir}
                                                        //rotaDinamica={() => isRotaDinamica()}
                                                        onClose={() => { setOpenTransferir(false); atualizarMapa() }}
                                                        idsParadas={idsSelecionados}
                                                        // idsEntregas={[moreInfo.entrega.id]}
                                                        // idsNotasFiscais={[moreInfo.notaFiscal != null ? moreInfo.notaFiscal.id : null]}
                                                        rotaId={props.match.params.id}
                                                        openSnackbar={openSnackbar}
                                                    />
                                                )}
                                            </SharedSnackbarConsumer>
                                        </div>
                                    </Tooltip>
                                )}
                            </SharedSnackbarConsumer>

                        </Tooltip> : <></>}
                </Grid>
                <Grid item xs={12} lg={1}>
                    {tamanho > 0 ?
                        <Tooltip title="Excluir paradas da rota" style={{ width: '100%', height: '100%' }}>
                            <SharedSnackbarConsumer>
                                {({ openSnackbar }) => (
                                    <Tooltip title="Excluir paradas da rota">
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <Button variant="outlined" color="primary" style={{ width: '100%', height: '100%' }} onClick={() => { excluirClick(openSnackbar) }} loading={loadingAdd}>
                                                Excluir
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </SharedSnackbarConsumer>

                        </Tooltip> : <></>}
                </Grid>
                <Grid xs={12} style={{ paddingLeft: '10px' }}>
                    <Typography variant="h7"> Total de paradas na rota: {entregas.length}</Typography>
                </Grid>
                <Grid item xs={12}>

                    {location !== null ? (
                        <MapSelectPoint
                            location={location}
                            entregas={entregas}
                            idsSelecionados={idsSelecionados}
                            tamanhoIcon={tamanhoIcone}
                            setIdsSelecionado={(ids) => {
                                setTamnho(ids.length);
                                setidsSelecionados(ids);
                                setCaculaPeso(ids);
                            }}
                        >
                        </MapSelectPoint>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <CircularProgress
                                    sx={{
                                        margin: "80px 0px"
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid >
    );
}
