import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";


const DateTimeField = props => {
  const {
    meta: { submitting, error, touched },
    input: { onBlur, value, ...inputProps },
    required,
    validate,
    ref,
    label,
    ...others
  } = props;

  const onChange = date => {
    inputProps.onChange(date);
  };

  return (
    <KeyboardDateTimePicker
      {...inputProps}
      {...others}
      format="dd/MM/yyyy HH:mm"
      inputVariant="outlined"
      variant='inline'
      value={value ? new Date(value) : null}
      error={error && touched}
      onChange={onChange}

      invalidDateMessage='Data invalida'
      style={{ width: '100%' }}
      required={required}
      validate={validate}
      ref={ref}
      label={label}

    />
  );
};

export default DateTimeField;