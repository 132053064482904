import { Alert, AlertTitle } from '@material-ui/lab';
import React, { Component } from 'react';
import { createRelatorio } from '../../API/Relatorios';
import Form from '../../Components/Form/Form';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import { getUser } from '../../Router/auth';
import Text from '@material-ui/core/Typography';

const tiposDeRelatorio = require('./tiposDeRelatorio.json');

class FormConfig extends Component {

    constructor(props) {
        super(props);

        var opcoes = tiposDeRelatorio
            .filter(item => item.empresasIds === null || item.empresasIds === undefined || item.empresasIds.length === 0 || item.empresasIds.includes(getUser().empresaId))
            .map(item => ({
                value: item.id,
                label: item.nome
            }));

        var camposForm = [
            { name: "tipoDoRelatorio", label: "Tipo do relatório", type: "option", size: 4, required: true, options: opcoes, onChange: this.handleSelectChange },
        ];

        this.state = {
            alertSucess: false,
            loadingField: true,
            fields: camposForm,
            descricao: "",
            opcoes: opcoes,
            temPrevistoRealizado: false,
        };
    }

    componentDidMount() {
        this.setState({ loadingField: false });
    }

    handleSelectChange = (selectedOption) => {
        var tipoDoRelatorio = selectedOption.target.value;
        var campos = this.carregarCamposForm(tipoDoRelatorio);
        var descricao = this.carregarDescricao(tipoDoRelatorio);
        var temPrevistoRealizadoLocal = this.verificarPrevistoRealizado(tipoDoRelatorio);
        this.setState({ descricao: descricao });
        this.setState({ fields: campos });
        this.setState({ temPrevistoRealizado: temPrevistoRealizadoLocal })
    };

    carregarCamposForm = (tipoDoRelatorio) => {
        this.state.fields = this.state.fields.filter(x => x.name === "tipoDoRelatorio")
        return this.state.fields.concat(tiposDeRelatorio.find(x => x.id === tipoDoRelatorio && (x.empresasIds === undefined || x.empresasIds === null || x.empresasIds.length === 0 || x.empresasIds.includes(getUser().empresaId))).camposForm);
    }

    carregarDescricao = (tipoDoRelatorio) => {
        return tiposDeRelatorio.find(x => x.id === tipoDoRelatorio).descricao;
    }

    verificarPrevistoRealizado = (tipoDoRelatorio) => {
        return tiposDeRelatorio.find(x => x.id === tipoDoRelatorio).temPrevistoRealizado;
    }

    create = async relatorio => {
        this.setState({ loadingField: true });
        const diferencaEmMilissegundos = relatorio.DataFim - relatorio.DataInicio;
        const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

        if (relatorio.tipoDoRelatorio === 1 && diferencaEmDias >= 7) {
            this.props.openSnackbar('O período não pode ser maior que 7 dias!', 'error');
            this.setState({ loadingField: false });
            return;
        }

        if (diferencaEmDias > 31) {
            this.props.openSnackbar('O período não pode ser maior que 30 dias!', 'error');
            this.setState({ loadingField: false });
            return;
        }

        if (relatorio.DataInicio > relatorio.DataFim) {
            this.props.openSnackbar('A data inicial não pode ser maior que a final!', 'error');
            this.setState({ loadingField: false });
            return;
        }
        if (new Date(relatorio.DataInicio) < new Date("01/01/2024")) {
            this.props.openSnackbar('A data inicial não pode ser anterior ao dia 01/01/2024.', 'error');
            this.setState({ loadingField: false });
            return;
        }

        //#region nem pensar em dar manutenção
        //Quando escrevi esse código, apenas eu e Deus sabíamos oq estávamos fazendo. Agora só Deus mesmo sabe.
        var parametrosJsonLimpos = {};

        Object.keys(relatorio).forEach(function (key) {
            if (key !== "tipoDoRelatorio") {
                if (key === "DataInicio") {
                    let data = new Date(relatorio[key].setHours(21, 0, 1, 0));
                    parametrosJsonLimpos[key] = new Date(data.setDate(data.getDate() - 1))

                } else if (key === "DataFim") {
                    parametrosJsonLimpos[key] = new Date(relatorio[key].setHours(20, 59, 59, 999));
                }
                else {
                    parametrosJsonLimpos[key] = relatorio[key];
                }
            }
        }
        );

        parametrosJsonLimpos["EmpresaId"] = getUser().empresaId;

        const parametrosRelatorio = {
            empresaId: getUser().empresaId,
            tipoDoRelatorio: relatorio.tipoDoRelatorio,
            dataInicio: parametrosJsonLimpos.DataInicio,
            dataFim: parametrosJsonLimpos.DataFim,
            parametrosJson: JSON.stringify(parametrosJsonLimpos),
        };
        //#endregion

        createRelatorio(parametrosRelatorio).then((value) => {
            if (value !== undefined) {
                this.props.openSnackbar('Relatório solicitado com sucesso!');
                this.setState({ alertSucess: true });
                this.setState({ loadingField: false });
            } else {
                this.props.openSnackbar('Erro ao solicitar relatório!', 'error');
                this.setState({ alertSucess: false });
                this.setState({ loadingField: false });
            }
        });
    }

    render() {

        return (
            <div>
                <Form
                    title="Novo relatório"
                    form="relatorioNew"
                    CanNotSave={true}
                    fields={this.state.fields}
                    CanBack={true}
                    onSubmit={this.create}
                    AlertSucess={this.state.alertSucess}
                    SalvarEVoltarLabel={"Solicitar"}
                    loading={this.state.loadingField}
                />

                <div style={{ paddingLeft: "1.5em" }}>
                    {this.state.descricao.toString() !== "" && this.state.descricao.toString() != null ? (

                        <Alert severity="info" >
                            <AlertTitle>Descrição do relatório</AlertTitle>
                            <Text style={{ whiteSpace: 'pre-line' }}>
                                {this.state.descricao}
                            </Text>
                        </Alert>
                    ) : <></>}
                    {this.state.temPrevistoRealizado === true ?
                        <Alert severity="warning" style={{ marginTop: "1em" }} >
                            <AlertTitle>Previsto X Realizado</AlertTitle>
                            <Text>Ao extrair o relatório, haverá campos comparando a quilometragem prevista e a realizada na(s) rota(s). É importante atentar que a quilometragem realizada é uma estimativa aproximada, pois os cálculos são baseados nas informações recebidas do GPS (do celular ou rastreador), sujeitas a variações devido à instabilidade de sinal em algumas áreas.</Text>
                        </Alert>
                        : <></>}
                </div>
            </div>
        );
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} />
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;
