import React, { useEffect, useContext } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll, deleteById } from '../../API/ParadaPlanejadas';
import { getDescricaoByLocais } from '../../API/Local';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import { getUser } from '../../Router/auth';
import { ReactComponent as pinEdit } from '../../img/icon/pinEdit.svg';
import { DialogSelectRota } from './Componentes/DialogSelectRota';
import { NFContext } from "../../Context/NFContext"
import { DialogSelectStatus } from './Componentes/DialogSelectStatus';
import { columnsGrid } from './Config';
import ControleDeAcesso from '../../Components/ControleDeAcesso/ControleDeAcesso';
import { Roles } from '../../Utils/Roles';
import { getEmpresa } from '../../Router/auth';
import { EhTransportadora, EmpresaIds } from '../../Utils/Empresa';
import { IconButton } from '@mui/material';
import { SequenciaFixadaContext } from '../../Context/SequenciaFixadaContext';
import ShuffleIcon from '@mui/icons-material/Shuffle';

export default function GridList(props) {
    const { openAdicionarNF } = useContext(NFContext);
    const { setParadasPlanejadas, setOpen } = useContext(SequenciaFixadaContext);

    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [rota, setRota] = React.useState(-1);
    const [loading, setLoading] = React.useState(true);
    const [openTransferir, setOpenTransferir] = React.useState(false);
    const [openAlterarStatus, setOpenAlterarStatus] = React.useState(false);

    useEffect(() => {
        setParadasPlanejadas(Array.from(rows))
    }, [setParadasPlanejadas, rows]);

    async function loadRow() {
      var rotaId = '';
      if (props.filter) {
        if (props.filter.rotaId)
            rotaId = props.filter.rotaId;
        setRota(rotaId);
      }

      setRows([]);
      setLoading(true);

      getAll(rotaId)
        .then(async (response) => {
            if (response !== undefined) {
                response.data.forEach(x => {
                    x.statusRoteirizacao = props.statusRoteirizacao;
                    x.desabilitado = props.desabilitado;
                    x.atualizarLista = loadRow;
                });

          //Get descricao locais planejados
          var locais = response.data.filter(e => e.localId != null);
          var locaisIds = locais.map(function (x) { return x.localId; });
          var descricoesLocais = await getDescricaoByLocais(locaisIds)
          if (descricoesLocais !== undefined) {
            descricoesLocais.data.forEach(descricao => {
              var r = response.data.find(e => e.localId === descricao.id);
              if (r != null) {
                r.descricao = descricao.descricao;
                r.tipoLocal = descricao.tipo;
              }
            });
          }

          setLoading(false);
          setRows([]);
          setRows(response.data);
        }

        setRows(response.data);
        setLoading(false);
      });

      //alterando visibilidade das colunas do frete e do prazo de entrega
      if (!(EhTransportadora(getEmpresa().id))) {
        let indexColFrete = columns.findIndex(el => {
          return el.field === String('valorTotalFrete');
        });

        let indexColPrazoEntrega = columns.findIndex(el => {
          return el.field === String('previsaoEntrega');
        });

        if (indexColFrete !== -1) {
          columns.splice(indexColFrete, 1);
          columns.splice(indexColPrazoEntrega, 1);
        }
      }

      //Oculta a coluna Documento mais antigo paras as empresas diferentes da Maestri(pode ser que tenha mais)
      var empresaId = getUser().empresaId;
      if (empresaId !== 173) {
        let indexDocumentoMaisAntigo = columns.findIndex((el) => {
          return el.field === String("dataEmissao");
        });
        if (indexDocumentoMaisAntigo !== -1) {
          columns.splice(indexDocumentoMaisAntigo, 1);
        }
      }
      setColumns(columnsGrid);
    }

    function handleClose2() {
        setOpenTransferir(false);
        loadRow();
    }

    function handleClose3() {
        setOpenAlterarStatus(false);
        loadRow();
    }

    useEffect(async () => {
        loadRow();
    }, [props.statusRoteirizacao]);

    useEffect(async () => {
        if (!openAdicionarNF) {
            loadRow();
        }
    }, [openAdicionarNF]);

    async function excluirClick(ids) {
        for (let index = 0; index < ids.length; index++) {
            try {
                const id = ids[index];
                await deleteById(id);
            } catch (e) { }
        }
        loadRow();
    }

    const adicionarNaRota = (ids) => {
        return (
            <>
                <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Logistica]}>
                    <SharedSnackbarConsumer>
                        {({ openSnackbar }) => (
                            <Tooltip title="Transferir paradas para outra rota">
                                <div style={{ width: '240px' }}>
                                    <Button variant="contained" color="primary" onClick={() => { setOpenTransferir(true); }}>
                                        Transferir de rota
                                    </Button>
                                    <DialogSelectRota open={openTransferir} rotaDinamica={!props.desabilitado} onClose={handleClose2} idsParadas={ids} rotaId={rota} openSnackbar={openSnackbar} />
                                </div>
                            </Tooltip>
                        )}
                    </SharedSnackbarConsumer>
                </ControleDeAcesso>

                <ControleDeAcesso rolesPermitidas={[Roles.Master]}>
                    <Tooltip title="Alterar status das paradas">
                        <div>
                            <IconButton aria-label="delete" size="large" onClick={() => { setOpenAlterarStatus(true); }} >
                                <SvgIcon component={pinEdit} />
                            </IconButton>
                            <DialogSelectStatus open={openAlterarStatus} onClose={handleClose3} idsParadas={ids} rotaId={rota} />
                        </div>
                    </Tooltip>
                </ControleDeAcesso>
            </>
        )
    }

    const actions = () => {
        const user = getUser();

        return (
            <>
                {((user.empresaId === EmpresaIds.Nutricol 
                    || user.empresaId === EmpresaIds.Barracao 
                    || user.empresaId === EmpresaIds.metalnox 
                    || user.empresaId === EmpresaIds.Rodosuper 
                    || user.empresaId === 167
                    || user.empresaId === 161 
                    || user.empresaId === 149
                    || user.empresaId === 175
                    || user.empresaId === 173
                    || user.role === Roles.Suporte) && user.role !== Roles.Monitoramento) ? (
                    <Tooltip title="Definição da sequência manual">
                        <IconButton onClick={() => setOpen(true)}>
                            <ShuffleIcon />
                        </IconButton>
                    </Tooltip>
                ) : <></>}
            </>
        );
    }

    return (
        <GridTable
            title=""
            titleNovo="Nova"
            rows={rows}
            columns={columns}
            CanRemove={getUser().role === Roles.Monitoramento ? false : props.podeRemover}
            rowsPerPage={100}
            excluirClick={excluirClick}
            actionsWhenSelect={adicionarNaRota}
            actions={actions}
            loading={loading}
        />
    );
}
