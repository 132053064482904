import { instance } from './instance/axios'

export async function getAll(notaFiscalId) {
    try {
      const response = await instance.get('/api/ItensNotaFiscal/grid?notaFiscalId='+notaFiscalId);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }