import { getUser } from "../../Router/auth";
import React from 'react';
import { instance } from '../../API/instance/axios';
import CardInformacoesComGrafico from './DashboardCardComGrafico'
import { GRAY, VERMELHO_PASTEL, VERDE_PASTEL } from '../../Utils/ColorsGrafos';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import { ReactComponent as BoxIcon } from '../../img/icon/box_icon.svg'
import { Box } from "@mui/material";

export default function PesoVolume(props) {
    const [infoPeso, setInfoPeso] = React.useState("");
    const [tooltipTextPeso, setTooltipTextPeso] = React.useState("");
    const [barrasPeso, setBarrasPeso] = React.useState([]);
    const [infoVolume, setInfoVolume] = React.useState("");
    const [tooltipTextVolume, setTooltipTextVolume] = React.useState("");
    const [barrasVolume, setBarrasVolume] = React.useState([]);


    React.useEffect(async () => {
        var filtro = {
            rotasIds: props.rotas.map(x => x.id),
            empresaId: getUser().empresaId,
        }

        var response = await instance.post("api/Dashboard/GetPesoVolumeByRotasIds", filtro);

        if (response.status == 200) {
            var peso = response.data.peso;
            var volume = response.data.volume;
            setInfoPeso(peso.pesoUtilizado + " kg");
            setInfoVolume(volume.volumesUtilizados);
            if (peso.percentualUtilizado > 100) {
                setBarrasPeso([
                    { cor: VERMELHO_PASTEL, percentual: peso.percentualUtilizado + "%" },
                    { cor: 'rgb(187 187 187)', percentual: peso.percentualDisponivel + "%" }
                ])
            } else {
                setBarrasPeso([
                    { cor: VERDE_PASTEL, percentual: peso.percentualUtilizado + "%", hideText: peso.percentualUtilizado < 15 ? true : false },
                    { cor: 'rgb(187 187 187)', percentual: peso.percentualDisponivel + "%", hideText: peso.percentualDisponivel < 15 ? true : false }
                ])
            }
            if (volume.percentualUtilizado > 100) {
                setBarrasVolume([
                    { cor: VERMELHO_PASTEL, percentual: volume.percentualUtilizado + "%" },
                    { cor: 'rgb(187 187 187)', percentual: volume.percentualDisponivel + "%" }
                ])
            } else {
                setBarrasVolume([
                    { cor: VERDE_PASTEL, percentual: volume.percentualUtilizado + "%", hideText: volume.percentualUtilizado < 15 ? true : false },
                    { cor: 'rgb(187 187 187)', percentual: volume.percentualDisponivel + "%", hideText: volume.percentualDisponivel < 15 ? true : false }
                ])
            }
            setTooltipTextPeso(`Utilizado: ${peso.pesoUtilizado} kg (${peso.percentualUtilizado + "%"})\nDisponível: ${peso.pesoDisponivel} kg (${peso.percentualDisponivel + "%"})\n\n Limite: ${peso.limiteDePeso} kg (100%)`)
            setTooltipTextVolume(`Utilizado: ${volume.volumesUtilizados} volumes (${volume.percentualUtilizado + "%"})\nDisponível: ${volume.volumesDisponiveis} volumes (${volume.percentualDisponivel + "%"})\n\n Limite: ${volume.limiteDeVolumes} volumes (100%)`)
        } else {
            setInfoPeso("");
            setInfoVolume("");
            setBarrasPeso([]);
            setBarrasVolume([]);
        }
    }, [props.rotas]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: 1
                }}
            >
                <CardInformacoesComGrafico
                    title={"Peso"}
                    info={infoPeso}
                    backgroundColor={'rgb(187 187 187)'}
                    icon={<FitnessCenterIcon />}
                    chartBars={barrasPeso}
                    rotas={props.rotas}
                    tooltipText={tooltipTextPeso}
                />

                <CardInformacoesComGrafico
                    title={"Volumes"}
                    info={infoVolume}
                    backgroundColor={'rgb(187 187 187)'}
                    icon={<BoxIcon />}
                    chartBars={barrasVolume}
                    rotas={props.rotas}
                    tooltipText={tooltipTextVolume}
                />
            </Box>
        </>
    );
}