import React from 'react';
import { Container } from '@mui/material';

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
  } from '@mui/material';
import {formatarNumeroParaReais} from '../../../Utils/Money';
import {getFormatDataTimeToStringComAsSemAno,getFormatMinutosToStringExt}from '../../../Utils/FormatDateTime';
import CardInformacoes from "../../../Components/CardInformacoes/CardInformacoes";
import DescriptionIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import { VERMELHO_PASTEL_2 } from '../../../Utils/ColorsGrafos';


const primeiroNome = (nome)=>{
  return nome.split(' ')[0];
}
const segundoNome = (nome)=>{
  var n = nome.split(' ')[0];
  if (nome.split(' ').length > 1)
    n += " "+ nome.split(' ')[1];
  return n;
}

const setorEntrega = (setor)=>{
  if (setor === '2 MOTOBOY - Entrega') return 'MOTOBOY';
  if (setor === '1 RETIRA na loja') return 'RETIRA';
  if (setor === '3 ROTAS Krambeck') return 'ROTA';
  return setor;
}


const List = (props) => {

      const StatusList = (cc) => (
        <Grid container spacing={0}>

            <Grid item xs={6} sm={6} md={6} key={-2} style={{paddingRight: 5}}>
              <CardInformacoes title={"Total"}
                info={props.data.length+" pedidos"}
                icon={<DescriptionIcon/>}
                />
            </Grid>

            <Grid style={{paddingLeft: 5}} item xs={6} sm={6} md={6} key={-2}>
            <CardInformacoes title={"Atrasados"}
                info={props.atrasados+" pedidos"}
                icon={<WarningIcon/>}
                 backgroundColor={(props.atrasados > 0 ? VERMELHO_PASTEL_2: '')}
                />
            </Grid>  
        </Grid>

      );

      const Texto = (props)=>{
        return <Typography
        color="textSecondary"
        style={{ fontSize: '20px'}}
    >
            {props.children}
        </Typography>
      }
      const TextoCabecalho = (props)=>{
        return <Typography
        // color="textSecondary"
        style={{ fontSize: '20px'}}
    >
        {props.children}
            {/* <b>{props.children}</b> */}
        </Typography>
      }
    
  

  return (

        <Container maxWidth={true} >
            <Typography variant="h4" gutterBottom align='center'>
                            {props.titulo}
                        </Typography>
            <StatusList></StatusList>
            <div style={{paddingBottom: 15}}></div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><TextoCabecalho>Número</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Modalidade</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Cliente</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Valor</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Vendedor</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Data/hora</TextoCabecalho></TableCell>
                    <TableCell><TextoCabecalho>Tempo na etapa</TextoCabecalho></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((documento) => (
                    <TableRow key={documento.id} sx={{ backgroundColor: documento.cor }}>
                      <TableCell><Texto>{documento.numeroDocumentoFiscal}</Texto></TableCell>
                      <TableCell><Texto>{setorEntrega(documento.setorEntrega)}</Texto></TableCell>
                      <TableCell><Texto>{segundoNome(documento.destinatarioDescricao)}</Texto></TableCell>
                      <TableCell><Texto>{formatarNumeroParaReais(documento.valorTotal)}</Texto></TableCell>
                      <TableCell><Texto>{primeiroNome(documento.vendedorDescricao)}</Texto></TableCell>
                      <TableCell><Texto>{getFormatDataTimeToStringComAsSemAno(documento.data)}</Texto></TableCell>
                      <TableCell><Texto>{getFormatMinutosToStringExt(documento.tempoEmMinutos)}</Texto></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
  );
};

export default List;
