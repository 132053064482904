export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GRAY = "#bdbdbd";
export const GREEN = "#5cb660";
export const RED = "#FF0000";

export const VERDE_FRACO = "#edf7ed";
export const VERDE_PASTEL = '#5cb660';
export const VERDE_PASTEL_2 = '#83c786';
export const VERMELHO_PASTEL = '#f55448';
export const VERMELHO_PASTEL_2 = '#f98b83';
export const VERMELHO_FRACO = "#EBB2B2";
export const AMARELO_PASTEL = '#f3a600';
export const AMARELO_PASTEL_2 = '#ebbb53';
export const AZUL_PASTEL = '#03a9f4';
export const LARANJA_DA_GRAFOS = '#fd7826';