import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getFormatEndereco_01 } from "../../Utils/Endereco";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const DraggableTable = forwardRef(({ rows }, ref) => {
    const [indexInMove, setIndexInMove] = useState(null);
    const [customRows, setCustomRows] = useState(rows);

    useImperativeHandle(ref, () => ({
        // garante que o index sera enviado começando pelo index = 1
        getCustomRows: () => customRows
            .map((row, index) => ({
                ...row,
                index: index + 1,
            }))
    }));

    useEffect(() => {
        const updatedRows = rows.map((row, index) => ({
            ...row,
            index: index,
        }));
        setCustomRows(updatedRows);
    }, [rows]);

    const handleDragStart = (e) => {
        setIndexInMove(e.source.index)
    }

    const handleDragEnd = (e) => {
        // Verifica se há um destino válido
        if (!e.destination) return;

        let tempData = Array.from(customRows);

        // Remove o item arrastado da lista temporária
        let [source_data] = tempData.splice(e.source.index, 1);

        // Verifica se o item no destino tem 'sequenciaRealizada' preenchido
        if (tempData[e.destination.index] && !tempData[e.destination.index].canDrag) {
            // Se sim, o item não pode ser movido, então volta o item arrastado à posição original
            tempData.splice(e.source.index, 0, source_data);
            setIndexInMove(null);
            return;
        }

        source_data.index = e.destination.index;
        tempData.splice(e.destination.index, 0, source_data);
        
        setCustomRows(tempData);
        setIndexInMove(null);
    };

    return (
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "50px" }}></TableCell>
                        <TableCell sx={{ width: "50px" }} align="center">Nova Sequência</TableCell>
                        {/* <TableCell sx={{ width: "50px" }} align="center">Sequência atual</TableCell> */}
                        <TableCell sx={{ width: "50px" }} align="center">Número do Documento</TableCell>
                        <TableCell>Destinatário</TableCell>
                        <TableCell>Endereço</TableCell>
                    </TableRow>
                </TableHead>
                <Droppable droppableId="droppable-1">
                    {(provider) => (
                        <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                            {customRows != null ? customRows.map((row, index) => (
                                <Draggable
                                    key={row.id}
                                    index={index}
                                    draggableId={`${row.id}`}
                                    isDragDisabled={!row.canDrag}
                                >
                                    {(provider) => (
                                        <TableRow                                     
                                            key={row.name}
                                            {...provider.draggableProps}
                                            ref={provider.innerRef}
                                            sx={{
                                                "&:last-child td, &:last-child th": {
                                                    border: 0
                                                }
                                            }}                                        
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                {...provider.dragHandleProps}
                                            >
                                                {/* situacao realizada */}
                                                {row.canDrag ? (
                                                    (indexInMove === index) ? (<UnfoldMoreIcon />) : <DragIndicatorIcon />
                                                ) : <></>}
                                            </TableCell>
                                            <TableCell align="center">{index + 1}</TableCell>                                           
                                            {/* <TableCell align="center">{row.sequencia}</TableCell> */}
                                            <TableCell align="center">{row.numeroDocumentoFiscal}</TableCell>                                            
                                            <TableCell align="left">{row.descricao}</TableCell>
                                            <TableCell align="left">{getFormatEndereco_01(row.endereco)}</TableCell>
                                        </TableRow>
                                    )}                                  
                                </Draggable>
                            )) : <></>}
                            {provider.placeholder}
                        </TableBody>                
                    )}
                </Droppable>
            </Table>
        </DragDropContext>
    )  
});

export default DraggableTable;