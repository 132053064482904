import { getUser } from "../../Router/auth";
import React from 'react';
import { instance } from '../../API/instance/axios';
import { GRAY} from '../../Utils/ColorsGrafos';
import CommentIcon from '@material-ui/icons/Comment';
import CardInformacoes from "./DashboardCard";

export default function Comentario(props) {
    const [info, setInfo] = React.useState("");
    

    React.useEffect(async () => {
        var filtro = {
            rotasIds: props.rotas.map(x => x.id),
            empresaId: getUser().empresaId,
        }

        var response = await instance.post("api/Dashboard/GetQuantComentariosByRotasIds", filtro);

        if(response.status === 200){
            setInfo(response.data.quantidadeDeComentarios.toString());
            
        } else{
            setInfo("");
        }
    }, [props.rotas]);

    return (
        <>
            <CardInformacoes
                title={"Comentários"}
                info={info}
                backgroundColor={GRAY}
                icon={<CommentIcon/>}
                size={props.size}
            />
        </>
    );
}