import { Box, CircularProgress, Grid, Skeleton } from "@mui/material";
import Form from "../../Components/Form/Form";
import { useState } from "react";
import { getById } from "../../API/MS_Frota/Abastecimento";
import { geMidiatByResposta } from "../../API/MS_Forms/Midia"
import { camposForm } from "./Config";
import Carousel from "../../Components/Carousel/Carousel";

function AbastecimentoForm({ id }) {
    const [formData, setFormData] = useState({});
    const [midiasData, setMidiasData] = useState(null);
    const [loadingMidias, setLoadingMidias] = useState(true);

    useState(() => {
        setLoadingMidias(true);
        getById(id)
            .then(resp => {
                if (resp != null) {
                    setFormData(resp.data);

                    geMidiatByResposta(resp.data.formularioRespostaId)
                        .then(midiaResp => {
                            if (midiaResp != null) {
                                setMidiasData(midiaResp.data);
                            }
                        })
                        .finally(_ => {
                            setLoadingMidias(false);
                        });
                } else {
                    setLoadingMidias(false);
                }
            });
    }, []);

    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}

                sx={{
                    width: "100%",
                    marginBottom: "30px"
                }}
            >
                <Form
                    form="abastecimentoForm"
                    CanNotSave={true}
                    fields={camposForm}
                    initialValues={formData}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}

                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {!loadingMidias ? (
                    midiasData != null && (
                        <Carousel images={
                            midiasData.map(x => ({
                                url: x.pathServidor,
                                latitude: x.latitude,
                                longitude: x.latitude
                            }))}
                        />
                    )
                ) : (
                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                        />
                        <CircularProgress
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }}
                        />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}

export default AbastecimentoForm;