import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Chip, Skeleton, TablePagination } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getAllForAjusteEnderecoManual } from '../../../../API/Enderecos';
import { AjusteEnderecoContext } from '../../../../Context/AjusteEnderecoContext';
import { getRotasIdsForAjusteEnderecoManual } from '../../../../API/Rotas';
import { getFormatDataTimeToString, getFormatDataToDataApi } from '../../../../Utils/FormatDateTime';
import { getUser } from '../../../../Router/auth';
import { EmpresaIds } from '../../../../Utils/Empresa';
import { Roles } from '../../../../Utils/Roles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const EnderecoList = ({ byEmpresa }) => {
    const {
        enderecos,
        setEnderecos,
        createEnderecoStr,
        enderecoAjuste,
        setEnderecoAjuste,
        expanded,
        setExpanded,
        filtroApi,
        setFiltroApi
    } = useContext(AjusteEnderecoContext);

    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [rotas, setRotas] = useState([]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setExpanded(0);
        setFiltroApi(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setFiltroApi(prevState => ({
            ...prevState,
            page: 0,
            maxRegistro: parseInt(event.target.value)
        }));
    };

    const handleChipColor = (item) => {
        if (item.ok) {
            return "success";
        }

        if (item.checkOsm < 2) {
            return "error";
        }

        if (item.checkOsm >= 2) {
            return "warning";
        }
    }

    useEffect(() => {
        const fetchData = () => {
            try {
                setLoading(true);

                const empresaId = getUser().empresaId;
                if (empresaId === EmpresaIds.Panorama) {
                    filtroApi.dataInicio = getFormatDataToDataApi("2024-11-20");
                }
                if (empresaId === 136) {
                    filtroApi.dataInicio = getFormatDataToDataApi("2025-03-13");
                }

                filtroApi.empresaId = byEmpresa ? empresaId : null
                getAllForAjusteEnderecoManual(filtroApi)
                    .then(resp => {
                        setCount(resp.count);
                        setEnderecos(resp.data);
                        setEnderecoAjuste(resp.data[0]);
                    }).finally(f => {
                        setLoading(false);
                    });
            } catch {
                setEnderecos([]);
            }
        }

        fetchData();
    }, [filtroApi, setEnderecoAjuste, setEnderecos, byEmpresa]);

    useEffect(() => {
        const fetchData = () => {
            try {
                getRotasIdsForAjusteEnderecoManual(enderecoAjuste.id, enderecoAjuste.empresaId)
                    .then(resp => {
                        setRotas(resp);
                    });
            } catch {
                setRotas([]);
            }
        }

        fetchData();
    }, [enderecoAjuste]);

    if (loading) {
        return (
            <Box>
                {[1, 2, 3, 4].map((index) => (
                    <Skeleton
                        key={index}
                        variant="rounded"
                        width={"100%"}
                        height={60}
                        sx={{
                        margin: "10px 0px"
                        }}
                    />
                ))}
            </Box>
        )
    }

    if (!enderecos || enderecos.length === 0) {
        return (
            <Box
                sx={{
                    width: "100%",
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Typography
                    variant="h4"
                >
                    Nenhum endereço encontrado
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Box
                sx={{
                    maxHeight: "900px",
                    overflow: "scroll",
                    overflowX: "hidden"
                }}
            >
                {enderecos.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        onClick={() => setEnderecoAjuste(item)}
                    >
                        <AccordionSummary>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"

                            }}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        marginRight: "15px"
                                    }}
                                >
                                    {createEnderecoStr(item)}
                                </Typography>
                                {(getUser().role === Roles.Suporte) ? (
                                    <Chip
                                        sx={{
                                            height: 'auto',
                                            padding: "5px",
                                        }}
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: "14px"
                                                }}
                                            >
                                                {item.empresaId}
                                            </Typography>
                                        }
                                        color={handleChipColor(item)}
                                    />
                                ) : (item.ok ? (
                                    <Chip
                                        sx={{
                                            height: 'auto',
                                            padding: "5px",
                                        }}
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: "14px"
                                                }}
                                            >
                                                Ok
                                            </Typography>
                                        }
                                        color={handleChipColor(item)}
                                    />
                                ) : <></>
                                )}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Criado em: {getFormatDataTimeToString(item.createdDate)}
                            </Typography>

                            {(rotas != null && rotas.length > 0) && (
                                <>
                                    {rotas.map((rota, index) => {
                                        return (    
                                            <Typography key={index} >
                                                Rota: {rota.idExterno} ({rota.id}) - {rota.regiao} - {getFormatDataTimeToString(rota.data)} - {rota.placa} - {rota.nomeMotorista}
                                            </Typography>
                                        )
                                    })}
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            <Accordion>
                <AccordionSummary
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end"
                    }}
                >
                    <TablePagination
                        count={count}
                        page={filtroApi.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={filtroApi.maxRegistro}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </AccordionSummary>
            </Accordion>
        </Box>
    );
}