import { instance } from './instance/axios'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getResumoEntrega(data) {
  try {

    var it = new Date(data);
    var i = it.getFullYear() + '-' + ('00' + (it.getMonth() + 1)).slice(-2) + '-' +
      ('00' + it.getDate()).slice(-2);

    const response = await instance.get(`/api/Entregas/resumo?data=${i}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}
export async function getEntregas(dtInicio, dtFim, idVeiculo = '') {
  try {
    var i = getFormatDataToDataApi(dtInicio);
    var f = getFormatDataToDataApi(dtFim);

    const response = await instance.get(`/api/Entregas?inicio=${i}&fim=${f}&veiculoId=${idVeiculo}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getPesoTotal(ids) {
  try {

    const response = await instance.put('/api/Entregas/PesoTotal', ids);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEntregasPendentes(dtInicio, dtFim) {
  try {
    var i = getFormatDataToDataApi(dtInicio);
    var f = getFormatDataToDataApi(dtFim);

    const response = await instance.get(`/api/Entregas/entregasPendentesSemRota?dataInicio=${i}&dataFim=${f}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}
export async function getEntregasPendentes2(dtInicio, dtFim, filtro) {
  try {
    var i = getFormatDataToDataApi(dtInicio);
    var f = getFormatDataToDataApi(dtFim);

    const response = await instance.post(`/api/Entregas/entregasPendentesSemRota_2?dataInicio=${i}&dataFim=${f}`,
      {
        filtro: filtro,
        page: 0,
        maxRegistro: 50
      });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getAll(rotaId = '', situacoesPendentes = false) {
  try {
    var parametro = (rotaId !== '' ? 'rotaId=' + rotaId : '');
    if (situacoesPendentes) {
      if (parametro !== '')
        parametro = parametro + '&';
      parametro = parametro + 'situacoesPendentes=' + situacoesPendentes;
    }

    const response = await instance.get('/api/Entregas/grid' + (parametro !== '' ? '?' + parametro : ''));
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Entregas/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function post(registro) {
  try {

    const response = await instance.post('/api/Entregas', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function put(id, registro) {
  try {

    const response = await instance.put('/api/Entregas/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function addEntregasInRota(idRota, ids) {
  try {

    const response = await instance.put('/api/Entregas/AddInRota/' + idRota, ids, { timeout: 30000 });
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function duplicaAddEntregasInRota(idRota, id, peso) {
  try {
    const response = await instance.post('/api/Entregas/DuplicarAddInRota/' + idRota, {id:id, peso: peso}, { timeout: 30000 });
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export async function getDescricaoByEntregas(ids) {
  try {

    const response = await instance.put('/api/Entregas/GetDescricaoEntregas', ids);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}