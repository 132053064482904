import { useCallback, useEffect, useState } from "react";
import { GridTable } from "../../Components/GridTable/GridTable";
import { columnsGrid } from "./Config";
import { getAll } from "../../API/MS_Frota/Abastecimento";
import { getFormatDataToDataApi } from "../../Utils/FormatDateTime";
import { Alert, Box, Grid, IconButton, Modal } from "@mui/material";
import DashboardCard from "../../Components/Dashboards/DashboardCard";
import { GRAY } from "../../Utils/ColorsGrafos";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FunctionsIcon from '@mui/icons-material/Functions';
import { KeyboardDatePicker } from "@material-ui/pickers";
import MultiplaSelecao from "../../Components/MultiplaSelecao/MultiplaSelecao";
import debounce from "lodash.debounce";
import { abastecimento_veiculos } from "../../Utils/StorageKeys";
import AbastecimentoForm from "./Form";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function AbastecimentoGridList() {
    const [dataInicio, setDataInicio] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [dataFim, setDataFim] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [rows, setRows] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);
    const [open, setOpen] = useState(false);

    // temporario até nao ter o GridV2
    const location = useLocation();
    const [id, setId] = useState();

    const [veiculosIds, setVeiculosIds] = useState(() => {
        const storedVeiculos = localStorage.getItem(abastecimento_veiculos);
        return storedVeiculos ? storedVeiculos.split(",") : [];
    });

    const [dashboardData, setDashboardData] = useState(null);

    const loadRows = useCallback(() => {
        setRows([]);
        setLoadingRows(true);

        getAll(getFormatDataToDataApi(dataInicio), getFormatDataToDataApi(dataFim), veiculosIds)
            .then(resp => {
                if (resp.data != null) {
                    setRows(resp.data);
                }
            })
            .catch(error => {

            })
            .finally(() => {
                setLoadingRows(false);
            });
    }, [dataInicio, dataFim, veiculosIds, setRows]);

    useEffect(() => {
        loadRows();
    }, [loadRows]);

    const debounceDataInicio = useCallback(debounce(setDataInicio, 500), []);
    const debounceDataFim = useCallback(debounce(setDataFim, 500), []);

    const handleOnDoubleClick = () => {
        // temporario até nao ter o GridV2
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");
        setId(id);

        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    return (
        <>
            <Alert severity="info">
                O cálculo da média de abastecimentos é atualizado a cada 30 minutos. Para garantir um cálculo preciso da média de abastecimento, o tanque deve ser completamente cheio a cada reabastecimento.
                <br />
                Para visualizar mais informações do abastecimento, dê duplo clique na linha.
            </Alert>


            {/* por enquando nao mostra os cards */}
            <Grid sx={{ display: "none" }} container spacing={1}>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Valor total"}
                        info={dashboardData ? `R$ ${dashboardData.valorTotal}` : ""}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    {/* aqui poderia ser um card com porcentagem de cada tipo de combustivel */}
                    <DashboardCard
                        title={"Total abastecido"}
                        info={"100 L"}
                        icon={<LocalGasStationIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Km Rodados"}
                        info={"1000 Km"}
                        icon={<LocalShippingIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Valor por Km"}
                        info={"0,36/Km"}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Média"}
                        info={"10,5 Km/L"}
                        icon={<FunctionsIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Preço medio combustivel"}
                        info={"R$ 6,56"}
                        fontSize={"18px"}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
            </Grid>

            {/* <GridTableV2
                rows={rows}
                columns={columnsGridV2}
                formComponent={<AbastecimentoForm />}
            /> */}

            <GridTable
                title="Abastecimentos"
                rows={rows}
                columns={columnsGrid}
                rowsPerPage={50}
                atualizar={loadRows}
                loading={loadingRows}
                onDoubleClick={handleOnDoubleClick}
                camposFiltros={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <KeyboardDatePicker
                            showTodayButton
                            disableToolbar
                            autoOk
                            variant="inline"
                            onChange={debounceDataInicio}
                            label="Data inicial"
                            invalidDateMessage='Data invalida'
                            todayLabel='Hoje'
                            cancelLabel='Cancelar'
                            format="dd/MM/yyyy"
                            value={dataInicio}
                        />
                        <KeyboardDatePicker
                            showTodayButton
                            disableToolbar
                            autoOk
                            variant="inline"
                            onChange={debounceDataFim}
                            label="Data final"
                            invalidDateMessage='Data invalida'
                            todayLabel='Hoje'
                            cancelLabel='Cancelar'
                            format="dd/MM/yyyy"
                            value={dataFim}
                        />
                        <Box
                            sx={{
                                width: "300px",
                                height: "auto"
                            }}
                        >
                            <MultiplaSelecao
                                label="Veiculos"
                                placeholder="Selecione os veiculos"
                                localStorageName={abastecimento_veiculos}
                                endpoint="api/Veiculos/lookupMultiplo"
                                onChange={(ids) => { setVeiculosIds(ids) }}
                            />
                        </Box>
                    </Box>
                }
            />

            <Modal open={open} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90vw",
                        height: "90vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "0px",
                            right: "0px"
                        }}
                    >
                        <IconButton
                            onClick={handleCloseModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <AbastecimentoForm id={id} />
                </Box>
            </Modal >
        </>
    );
}

export default AbastecimentoGridList;
