import { Avatar, Box, Button, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Component, useEffect, useState } from "react";
import { getRotasByFiltro, unificarRotas } from "../../../API/Rotas";
import { getUser } from "../../../Router/auth";
import { SharedSnackbarConsumer } from "../../../Context/SharedSnackbar.context";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const DialogAgruparRotas = ({ rotaIds, onCloseCallback, ...props }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rotas, setRotas] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            getRotasByFiltro({
                rotasIds: rotaIds,
                empresaId: getUser().empresaId
            }).then(resp => {
                if (resp != null) {
                    setRotas(resp);
                }
            }).catch(err => {
                props.openSnackbar("Erro ao buscar rotas.", "error");
            }).finally(_ => {
                setLoading(false);
            });
        };

        if (open)
            fetchData();
    }, [open, rotaIds]);

    const agruparRotas = (id) => {
        setLoading(true);
        unificarRotas(id, rotaIds)
            .then(resp => {
                props.openSnackbar("Rotas unificadas com sucesso.");
            }).catch(error => {
                props.openSnackbar("Ocorreu um erro ao unificar as rotas", "error");
            }).finally(_ => {
                handleClose();
                setLoading(false);
            });
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        if (onCloseCallback != null || onCloseCallback !== undefined) {
            onCloseCallback();
        }
    }

    return (
        <>
            <Button
                variant="text"
                color="primary"
                size="large"
                startIcon={<CallMergeIcon />}
                onClick={handleOpen}>
                Agrupar
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={"lg"}
            >
                <DialogTitle>
                    Selecione a rota para a qual as paradas serão transferidas.
                </DialogTitle>

                {loading ? (
                    <Box
                        sx={{
                            margin: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {rotas.map((rota) => (
                            <ListItem
                                key={rota.id}
                                onClick={() => {
                                    agruparRotas(rota.id);
                                }}
                            >
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CallSplitIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${rota.rotaRegiaoDescricao ? `${rota.rotaRegiaoDescricao} -` : ''} ${opcaoStausRota.find(opcao => opcao.value === rota.status).label}`}
                                        secondary={`${rota.descricao} - ${rota.motoristaNome}`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Dialog >
        </>
    );
}

const opcaoStausRota = [
    { value: 1, label: 'Aguardando início' },
    { value: 2, label: 'Em rota' }
];

const ConfigWithRouter = withRouter(DialogAgruparRotas);

class DialogAgruparRotasSnackbar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <ConfigWithRouter
                        openSnackbar={openSnackbar}
                        {...this.props} >
                    </ConfigWithRouter>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default DialogAgruparRotasSnackbar;