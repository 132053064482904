import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, update, getById } from '../../API/ParadaPlanejadas';
import { getByIdByPessoa as getEnderecoByPessoa } from '../../API/Enderecos';
import { camposForm2, camposForm_Edit } from './Config';
import { getUser } from '../../Router/auth';
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import TabHorizontal, { TabPanel } from '../../Components/TabHorizontal/Tab';
import Tab from '@material-ui/core/Tab';
import { ParadaPlanejadaNewRoute } from '../../Router/Routing';
import { SubmissionError } from 'redux-form';
import TipoParadaPlanejada from './Componentes/TipoParadaPlanejada';

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;

        this.state = { loading: l, loadingField: true, values: { situacao: 7, tipo: 1 }, tipo: 1, camposForm: camposForm2, alertSucess: false };
    }

    limpar = () => {
        this.setState({ alertSucess: false, alertError: false });
    }

    create = async values => {
        values.tipo = this.state.tipo;

        if (values.rotaId === -1)
            values.rotaId = null;

        values.endereco = null;

        if (values.peso) {
            values.peso = values.peso.toString().replaceAll('.', '').replaceAll(",", ".");
        }
        if (values.valorTotal) {
            values.valorTotal = values.valorTotal.toString().replaceAll('.', '').replaceAll(",", ".");
        } 

        values.entrega = {
            nfs: [
                {
                    pesoBruto: values.peso ?? 0,
                    numeroDocumentoFiscal: values.numero,
                    total:{
                        valorTotal: values.valorTotal ?? 0
                    }
                }
            ]
        };

        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'Entrega does not exist', _error: 'Login failed!' })
        }
    }
    update = async values => {
        values.tipo = this.state.tipo;

        if (values.rotaId === -1)
            values.rotaId = null;
        var id = this.props.match.params.id;
        var retorno = await update(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'Entrega does not exist', _error: 'Login failed!' })
        }
    }

    changeDestinatario = value => {
        return this.changeDestinatarioById(value);
    }

    changeDestinatarioById = async id => {
        var retorno = await getEnderecoByPessoa(id);
        if (retorno !== undefined) {
            return {
                campos: [
                    { campo: 'enderecoId', value: { value: { id: retorno.id }, lookup_parametro: 'pessoaId=' + id } },
                ]
            };
        }
        return {
            campos: [
                { campo: 'enderecoId', value: { value: { id: -1, descricao: '' }, lookup_parametro: 'pessoaId=' + id } },
            ]
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            this.setState({ camposForm: camposForm_Edit });
            getById(id).then(async (value) => {
                if (value !== undefined) {
                    var retorno = await getEnderecoByPessoa(value.destinatarioId);
                    if (retorno !== undefined) {
                        var informacao = retorno.logradouro + ", " + retorno.numero + ", " + retorno.bairro + ", " + retorno.nomeMunicipio;
                        value.endereco = informacao;
                    }
                    this.setState({ values: value });
                }
                this.setState({ loading: false });

            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            var query = new URLSearchParams(this.props.location.search);

            var rotaId = query.get('rotaId');
            if (rotaId)
                this.state.values.rotaId = parseInt(rotaId);

            this.state.values.empresaId = getUser().empresaId;
            this.state.values.data = getFormatDataToDataApi(new Date());
            this.state.values.situacao = 7;
            this.state.values.destinatarioId = -1;
            this.setState({ values: this.state.values });

            this.setState({ loadingField: false });
        }

        var destin = this.state.camposForm.find(e => e.name === "destinatarioId");
        destin.onChange = this.changeDestinatario;

        this.setState({ loadingField: false });
    }

    changeTipo = (valor) => {
        this.setState();
        this.setState({ loading: true });
        var c = this.state.values;
        c.tipo = valor;
        this.setState({ values: c, tipo: valor, open: false });
        this.setState({ loading: false });

    }

    render() {
        var b = this.state.camposForm.find(e => e.name === "tipo");

        b.component = <TipoParadaPlanejada tipoParadaPlanejada={this.state.values.tipo} changeTipo={this.changeTipo}></TipoParadaPlanejada>;

        let id = this.props.match.params.id;
        if (id) {
            return (
                <div>
                    <TabHorizontal abas={[
                        <Tab label="Parada planejada" index={0} />
                    ]}>
                        <TabPanel index={0}>
                            <Form title="Editar" form="paradaFormEdit" fields={this.state.camposForm} AlertSucess={this.state.alertSucess} onSubmit={this.update}
                                LinkNew={ParadaPlanejadaNewRoute} CanBack={true} initialValues={this.state.values} loading={this.state.loading || this.state.loadingField}
                                limpar={this.limpar}
                            />
                        </TabPanel>
                    </TabHorizontal>


                </div>
            );
        } else {

            return (<>
                <Form title="Parada planejada"
                    form="paradaForm" fields={this.state.camposForm} onSubmit={this.create} AlertSucess={this.state.alertSucess} LinkNew={ParadaPlanejadaNewRoute}
                    CanNotSave={true} CanBack={true} initialValues={this.state.values} loading={this.state.loadingField}
                    limpar={this.limpar}
                >
                </Form>
            </>
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;