
import Button from '@material-ui/core/Button';
import { getToken } from '../../Router/auth';
import { Box } from '@mui/material';
import { AjusteEnderecoRouteStr,StatusRoteirizador } from '../../Router/Routing';

export default function BackendPage() {
  const AccessHangfire = () => {
    window.open('https://container-app-prod-ms-back-hang.ambitioushill-b68aeeee.eastus.azurecontainerapps.io/hangfire?access_token=' + getToken(), "_blank");
  }

  const AcessAjusteEndereco = () => {
    window.open(`${AjusteEnderecoRouteStr}`, "_blank")
  }
  const StatusRoteirizadorClick = () => {
    window.open(`${StatusRoteirizador}`, "_blank")
  }

  return (
    <Box>
      <Button variant="outlined" onClick={AccessHangfire}>Acessar Hangfire - Monitoramento</Button>
      <Button variant="outlined" onClick={AcessAjusteEndereco}>Acessar ajuste de endereço</Button>
      <Button variant="outlined" onClick={StatusRoteirizadorClick}>Roteirizador</Button>
    </Box>
  );
}