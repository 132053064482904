import { instance } from "./instance/axios";

export async function createByDocumento(pacoteId, xml) {
    try {
        const response = await instance.post(`/api/PacoteEvidenciaDocumento/CreateByDocumento/${pacoteId}`, xml);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getByPacoteEvidenciasId(pacoteId) {
    try {
        const response = await instance.get(`/api/PacoteEvidenciaDocumento/GetByPacoteEvidenciasId/${pacoteId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getWithStatus(id) {
    try {
        const response = await instance.get(`/api/PacoteEvidenciaDocumento/GetWithStatus/${id}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function deleteById(id) {
    try {
        const response = await instance.delete(`/api/PacoteEvidenciaDocumento/Delete/${id}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}