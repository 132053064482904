import { instance } from './instance/axios'

export async function getRoteirizadorContainer() {
    try {
      const response = await instance.get('/api/RoteirizadorContainer/Resumo');
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }