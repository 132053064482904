export const tipoCombustivel = {
    GasolinaComum: 0,
    GasolinaAditivada: 1,
    GasolinaPremium: 2,
    Etanol: 3,
    GNV: 4,
    DieselS10: 5,
    DieselS500: 6,
    Biodiesel: 7,
    Hidrogenio: 8,
    OVIN: 9,
    Biometano: 10,
    Arla: 11,
    Eletrico: 12
} 