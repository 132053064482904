import { instance } from './instance/axios';

export async function buscaCompletaNotaFiscal(filtroApi) {
    try {
        const response = await instance.post("/api/BuscaCompleta", filtroApi);

        if (response.status === 201 || response.status === 200) {
            return response.data
        }
    }
    catch (error) {
        throw error;
    }
}

export async function getBuscaCompletaEvidencia(entregaId) {
	try {
		const response = await instance.get(`/api/BuscaCompleta/Evidencias/${entregaId}`);
		if (response.status ===  200) {
			return response.data;
		}
	}
	catch (error) {
		if (error.response.status ===  404) {
			return null;
		}
		else {
			throw error;
		}
	}
}