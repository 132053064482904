import { createContext, useState } from 'react';

export const MonitoramentoContext = createContext();

export const MonitoramentoProvider = ({children}) => {
    const [atualizarParadas, setAtualizarParadas] = useState(false);
    const [atualizarRotas, setAtualizarRotas] = useState(false);

    return (
        <MonitoramentoContext.Provider
            value={{
                atualizarParadas, setAtualizarParadas,
                atualizarRotas, setAtualizarRotas
            }}
        >
            {children}
        </MonitoramentoContext.Provider>
    );
}
