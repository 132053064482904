import React from 'react';
import { Box, Container } from '@mui/material';
import { useEffect, useState } from "react";
import { getFormatTimeToString } from '../../Utils/FormatDateTime';
import List from "./Components/List";
import { Typography  } from '@mui/material';
import { getUser } from '../../Router/auth';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Tune';
import { monitoramentoEmSeparacao, monitoramentoAguardandoRota } from "../../API/NotasFiscais";
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { VERDE_PASTEL_2, AMARELO_PASTEL_2,VERMELHO_PASTEL_2 } from '../../Utils/ColorsGrafos';


const FullScreenDividedScreen = (props) => {

  const [dataMonitoramentoEmSeparacao, setDataMonitoramentoEmSeparacao] = useState([]);
  const [dataMonitoramentoAguardandoRota, setDataMonitoramentoAguardandoRota] = useState([]);
  const [monitoramentoEmSeparacaoAtrasados, setMonitoramentoEmSeparacaoAtrasados] = useState(0);
  const [monitoramentoAguardandoRotaAtrasados, setMonitoramentoAguardandoRotaAtrasados] = useState(0);
  
  
  const [time, setTime] = useState(Date.now());

  const alertas = [{tempo: 0, cor: VERDE_PASTEL_2}, {tempo: 8, cor: AMARELO_PASTEL_2},{tempo: 16, cor: VERMELHO_PASTEL_2, contabiliza: true}];
  

    useEffect(() => {
        atualizar();
        const interval = setInterval(() => {
            setTime(Date.now());
            atualizar();
        }, 1 * 15000);

        return () => {
            clearInterval(interval);
        }

    }, []);

    async function atualizar() {
      let dataAtual = new Date();
      dataAtual.setHours(0, 0, 0, 0);

      var filtroMonitoramentoEmSeparacao = {};
      var filtroMonitoramentoAguardandoRota ={};

      var empresaId = -1;
      if (getUser() != null)
        empresaId = getUser().empresaId;

      if (empresaId === 158)
      {
        filtroMonitoramentoEmSeparacao ={
          emitenteId: 1008476,
          dataInicio: getFormatDataToDataApi(dataAtual),
          setorEntrega: '2 MOTOBOY - Entrega',
          page: 0,
          maxRegistro: 200
        };
        filtroMonitoramentoAguardandoRota ={
          emitenteId: 1008476,
          setorEntrega: '2 MOTOBOY - Entrega',
          dataInicio: getFormatDataToDataApi(dataAtual),
          page: 0,
          maxRegistro: 200
        };
      }
      if (empresaId === 173)
      {
        filtroMonitoramentoEmSeparacao ={
          emitenteId: 1140687,
          dataInicio: getFormatDataToDataApi(dataAtual),
          page: 0,
          maxRegistro: 200
        };
        
        filtroMonitoramentoAguardandoRota ={
          emitenteId: 1140687,
          setorEntrega: '2 - ENTREGAR',
          dataInicio: getFormatDataToDataApi(dataAtual),
          page: 0,
          maxRegistro: 200
        };
      }
      
      monitoramentoEmSeparacao(filtroMonitoramentoEmSeparacao).then(resp => {
        var atrasados = 0;
        for (let i = 0; i < resp.data.length; i++) {
          for (let j = 0; j < alertas.length; j++) {
            if (resp.data[i].tempoEmMinutos > alertas[j].tempo)
            {
              resp.data[i].cor = alertas[j].cor;
              if (alertas[j].contabiliza)
                {
                  atrasados++;
                }
            }
          }
        }
        
        setDataMonitoramentoEmSeparacao(resp.data.sort((a, b) => { return new Date(a.data) - new Date(b.data) }));
        setMonitoramentoEmSeparacaoAtrasados(atrasados);
      }).catch(error => {
          props.openSnackbar("Ocorreu algum erro", "error");
      });

      
      monitoramentoAguardandoRota(filtroMonitoramentoAguardandoRota).then(resp => {
        var atrasados = 0;
        for (let i = 0; i < resp.data.length; i++) {
          for (let j = 0; j < alertas.length; j++) {
            if (resp.data[i].tempoEmMinutos > alertas[j].tempo)
            {
              resp.data[i].cor = alertas[j].cor;
              if (alertas[j].contabiliza)
              {
                atrasados++;
              }
            }
          }
        }
        setDataMonitoramentoAguardandoRota(resp.data.sort((a, b) => { return new Date(a.data) - new Date(b.data) }));
        setMonitoramentoAguardandoRotaAtrasados(atrasados);
      }).catch(error => {
          props.openSnackbar("Ocorreu algum erro", "error");
      });

    }

  return (
    <>
      <Container maxWidth={true} disableGutters style={{  display: 'flex', paddingLeft: '24px',paddingRight: '24px' }}>
        <Box sx={{ width: '50%', display: 'flex' }}>
        </Box>
        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'right', alignContent: 'center'}} >
          <Typography variant="subtitle1" sx={{ alignContent: 'center'}}>
                    Atualizado em  {time === '' ? '' : getFormatTimeToString(time)}
          </Typography>
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Container>
    
      <Container maxWidth={true} disableGutters style={{  display: 'flex',  }}>
          <List sx={{ width: '50%', display: 'flex', }} data={dataMonitoramentoEmSeparacao} titulo={'EM SEPARAÇÃO'} atrasados={monitoramentoEmSeparacaoAtrasados}/>
        
          <List sx={{ width: '50%', display: 'flex', }} data={dataMonitoramentoAguardandoRota} titulo={'AGUARDANDO ROTA'} atrasados={monitoramentoAguardandoRotaAtrasados}/>
        
      </Container>
    </>
  );
};

export default FullScreenDividedScreen;
