import { instance } from './instance/axios';

export async function getAll(filtro) {
    try {
      const response = await instance.post('/api/Relatorios/GetAll', filtro);
       if (response.status ===  200){
           return response.data;
       }
    } catch (error) {
      console.error(error);
    }   
}

export async function createRelatorio(relatorio) {
  try {
    const response = await instance.post('/api/Relatorios/GerarRelatorio', relatorio);
     if (response.status ===  200){
         return response.data;
     }
  } catch (error) {
    console.error(error);
  }   
}