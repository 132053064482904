import StatusRoute from '../../Components/StatusRoute';
import StatusRoteirizacao from '../../Components/StatusRoteirizacao/StatusRoteirizacao';
import Acoes from '../../Components/Acoes/Acoes';
import DataEmissaoDocAntigo from '../../Components/DocMaisAntigoDataEmissao/DataEmissaoDocAntigo';

export const columnsGrid = [
  {
		label: 'Rota',
		field: 'descricao',
		width: '300px',
  },
	{ label: 'Situação', field: 'status', type: 'custom', width: '100px', function: StatusRoute, align: "center" },
	{ label: 'Roteirização', field: 'statusRoteirizacao', type: 'customWithRow', padding: 'default', function: StatusRoteirizacao, width: '100px', align: "center", },
	{ label: 'Documento mais antigo', field: 'dataEmissao',  type: 'customWithRow', padding: 'default', width: '50px', align: "center", function: dataEmissaoComponent },
	{
		label: 'Paradas',
		field: 'paradasMonitormaneto',
		type: 'customWithRow',
		padding: 'default',
		width: '300px'
  },
	{ label: 'Ações', field: 'acoes', type: 'customWithRow', padding: 'default', function: Acoes, width: '100px', align: "center" },
];

function dataEmissaoComponent(value) {
  return <DataEmissaoDocAntigo row={value} />;
}

export const TipoParadaMonitoramento = {
	"Inicio": 0,
	"Interno": 1,
	"Mecanica": 2,
	"Entrega": 3,
	"Fim": 4,
	"ParadaNaoPlanejada": 5,
	"LocalPlanejado": 6,
	"Visita": 7,
	"Recolha": 8,
	"SemSinalGPS": 9
}

export const SituacaoParadaMonitoramento = {
	"Aguardando": 0,
	"Realizado": 1,
	"NaoPlanejado": 2,
	"EmAndamento": 3
}