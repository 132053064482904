import { get_DataHoraDocumentoMaisAntigo } from "../../API/NotasFiscais";
import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import {getFormatDataToString,getTime,getFormatMinutosToStringExt} from "../../Utils/FormatDateTime";

export default function DataEmissaoDocAntigo(props) {
  const [loading, setLoading] = useState(true);
  const [menorHoraDoc, setMenorHoraDoc] = useState(null);

  useEffect(() => {
    try {
      setLoading(true);
      get_DataHoraDocumentoMaisAntigo(props.row.id)
        .then((resp) => {
          setMenorHoraDoc(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, [props.row.id]);

  if (loading) {
    return (
      <div style={{ width: "100px", marginLeft: "10px" }}>
        <Skeleton variant="text" />
      </div>
    );
  }

  if (menorHoraDoc === null) return <></>;

  // Calculando a diferença de tempo
  const dataInicial = new Date(menorHoraDoc.dataEmissao);
  const dataFinal = new Date();

  let diferencaMilissegundos = Math.abs(dataFinal - dataInicial);
  const diferencaEmMinutos = diferencaMilissegundos / 1000 / 60;

  var tempoDaEmissaoDoDocumentoEmMinutos = 30;

  let cor = "black";
  if (diferencaEmMinutos <= tempoDaEmissaoDoDocumentoEmMinutos) {
    cor = "black";
  } else if (diferencaEmMinutos > tempoDaEmissaoDoDocumentoEmMinutos) {
    cor = "red";
  }

  if (props.row.status !== 1) {
    cor = "black";
  }

  const dataFormatada = getFormatDataToString(menorHoraDoc.dataEmissao);

  let horaFormatada = getTime(new Date(menorHoraDoc.dataEmissao));

  return (
    <Tooltip
      title={
        <Typography variant="subtitle1">
          <b>{dataFormatada}</b>
        </Typography>
      }
    >
      <div style={{ color: cor }}>
        {getFormatMinutosToStringExt(diferencaEmMinutos)}
        <br />
        {"Emissão: " + horaFormatada + "h"}
      </div>
    </Tooltip>
  );
}
