import { getToken, logout } from '../../Router/auth';
import { LoginRoute } from '../../Router/Routing';

const axios = require('axios');

const headers = () => {
    return {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
}

const instance_ms_forms = axios.create({
    baseURL: process.env.REACT_APP_URL_MS_FORMS,
    timeout: 10000,
    headers: headers()
});

instance_ms_forms.interceptors.request.use(
    (response) => {
        response.headers.Authorization = "Bearer " + getToken();
        return response;
    }
);

const setupInterceptors = history => {
    instance_ms_forms.interceptors.response.use(res => {
        return res
    }, err => {
        const { status } = err.response

        if (status === 401) {
            logout();
            history.push(LoginRoute)
        }

        return Promise.reject(err)
    })
}


export { instance_ms_forms, setupInterceptors };