import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { instance } from '../../API/instance/axios';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import debounce from "lodash.debounce";
import { getUser } from "../../Router/auth";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiplaSelecao(props) {
	const [opcoes, setOpcoes] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [placeholder, setPlaceholder] = useState(props.placeholder);

	var filtroApi = {
		filtro: "",
		page: 0,
		maxRegistro: 50,
		empresaId: getUser().empresaId,
		ids: []
	}

	function getIdsLocalStorage() {
		const textoArmazenamentoLocal = localStorage.getItem(props.localStorageName);

		if (textoArmazenamentoLocal) {
			const textoArmazenamentoLocalEmArray = textoArmazenamentoLocal.split(',');
			if (textoArmazenamentoLocalEmArray === " ") {
				return [];
			}
			return textoArmazenamentoLocalEmArray.map(str => parseInt(str, 10));
		}
		return [];
	}

	const handleUpdate = (event, values) => {
		let ids = getIdsLocalStorage();
		props.onChange(ids);
	}

	useEffect(() => {
		let idsLocalStorage = getIdsLocalStorage();

		getAll()
			.then(resp => {
				var novosDados = resp.map(({ id, descricao }) => ({ id, descricao }));
				setOpcoes(novosDados);

				setPlaceholder("");
				var selectedItems = novosDados.filter((item) => idsLocalStorage.includes(item.id));
				setSelectedOptions(selectedItems);
			});
	}, []);

	async function getAll(filtroText = "") {
		try {
			var filtro = filtroApi;
			filtro.ids = getIdsLocalStorage();
			filtro.filtro = filtroText;

			var response = await instance.post(props.endpoint, filtro);

			if (response.status === 200) {
				return response.data.registros;
			}
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	function handleDelete(id) {
		var selectedOptionsAtualizada = selectedOptions.filter(x => x.id !== id);
		setSelectedOptions(selectedOptionsAtualizada);

		getAll()
			.then(resp => {
				setOpcoes(resp);
			});

		localStorage.setItem(props.localStorageName, selectedOptionsAtualizada.map(x => x.id));
		props.onChange(selectedOptionsAtualizada.map(x => x.id));
	}

	const handleChange = async (event, newValue) => {
		if (newValue != null && newValue.length > 0) {
			localStorage.setItem(props.localStorageName, newValue.map(objeto => objeto.id));
			setPlaceholder("");
		} else {
			localStorage.setItem(props.localStorageName, "");
			setPlaceholder(props.placeholder);
			props.onChange(newValue);
		}

		getAll()
			.then(resp => {
				setOpcoes(resp);
				var selectedItems = resp.filter((item) => newValue.map(x => x.id).includes(item.id));
				setSelectedOptions(selectedItems);
			});
	};

	const handleChangeFiltro = (event) => {
		updateLista(event.target.value);
	};

	async function updateLista(filtro) {
		var ids = getIdsLocalStorage();
		var lista = await getAll(filtro);
		if (filtro !== "") {
			lista = lista.filter((item) => !ids.includes(item.id));
			setOpcoes(lista);
		} else {
			setOpcoes(lista);
			var selectedItems = lista.filter((item) => ids.includes(item.id));
			setSelectedOptions(selectedItems);
		}
	}

	const debouncedHandleChangeFiltro = useCallback(debounce(handleChangeFiltro, 300), []);

	return (
		<Autocomplete
			multiple
			limitTags={1}
			options={opcoes}
			value={selectedOptions}
			disableCloseOnSelect
			variant="inline"
			margin="normal"
			getOptionLabel={(option) => option.descricao}
			style={{ width: "100%" }}
			renderOption={(option, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{option.descricao}

				</React.Fragment>
			)}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<Chip
						label={option.descricao.length > 15 ? `${option.descricao.substring(0, 15)}...` : option.descricao}
						{...getTagProps({ index })}
						size='small'
						onDelete={() => {
							handleDelete(option.id)
						}}
					/>
				))
			}
			onChange={handleChange}
			onClose={handleUpdate}
			renderInput={(params) => (
				<TextField {...params} variant='standard' label={props.label} placeholder={placeholder} style={{ marginBottom: '0px' }} onChange={debouncedHandleChangeFiltro} />
			)}
		/>
	);
}

