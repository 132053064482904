import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getByIdTelaRota, transferirParada } from '../../API/Rotas';
import { getById as getByIdEmpresa } from '../../API/Empresa';
import { getVeiculos, getById as getVeiculoById } from '../../API/Veiculos';
import { camposForm } from './Config';
import { getUser, getEmpresa } from '../../Router/auth';
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import axios from 'axios'
import TabHorizontal, { TabPanel } from '../../Components/TabHorizontal/Tab';
import Tab from '@material-ui/core/Tab';
import Button from '../../Components/Button/Button';
import EntregasGrid from '../Entrega/GridList';
import EvidenciasGrid from '../Evidencia/GridList';
import { EntregasNewRoute2, ParadaPlanejadaNewRoute, RotasEditRoute, RotasTransfeirComMapaRoute, RotasSelectEntregasRoute, RotasSelectLocaisPlanejadosRoute } from '../../Router/Routing';
import ButtonRoteirizar from './Components/ButtonRoteirizar';
import ButtonImprimir from './Components/ButtonImprimir';
import GridList from '../ParadaPlanejada/GridList'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Entregaicon from '@material-ui/icons/AddLocation';
import EntregaMapaIcon from '@material-ui/icons/Map';
import { ReactComponent as notaFiscalIcon } from '../../img/icon/notaFiscal.svg';
import { ReactComponent as barcode } from '../../img/icon/barcode_icon.svg';
import EntregaListaicon from '@material-ui/icons/PlaylistAddCheck';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as localPlanejado_3 } from '../../img/icon/localPlanejado_4.svg';
import { withRouter } from "react-router-dom";
import { Alert, AlertTitle } from '@material-ui/lab';
import { getParametroEmpresa } from '../../Utils/ParametroEmpresa';
import RotaMapa from '../Mapa/RotaMapa';
import Importador from '../ImportarArquivos/Importador';
import CapacidadeRota_Form from './Components/CapacidadeRota_Form';
import RotaAdicionarNF from "../RotaAdicionarNF/RotaAdicionarNF.js"
import { NFContext } from '../../Context/NFContext';
import { Roles } from '../../Utils/Roles.js';
import ControleDeAcesso from '../../Components/ControleDeAcesso/ControleDeAcesso.js';
import QuilometragemPrevista_Form from './Components/QuilometragemPrevista_Form.js';
import FormatacaoDiferente_Form from './Components/FormatacaoDiferente_Form.js';
import ButtonComentarioRota from './Components/ButtonComentarioRota.js';
import { Box } from '@mui/material';
import { TabIndexForm } from './utils/enums.js';
import RegistroQuilometragemGrid from "../../Pages/RegistroQuilometragem/GridList";
import DialogDraggableTable from '../../Components/DraggableTable/DialogDraggableTable.js';
import { SequenciaFixadaContext } from '../../Context/SequenciaFixadaContext.js';
import { updateSequenciaFixada } from '../../API/ParadaPlanejadas.js';

class FormConfig extends Component {
    static contextType = NFContext;

    constructor(props) {
        super(props);

        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;

        var novaEntrega = getParametroEmpresa(getUser().empresaId, 'criarEntrega');

        this.state = {
            loading: l,
            loadingField: true,
            values: {},
            camposForm: camposForm,
            loadingRoteirizador: false,
            docNfs: [],
            filesReader: [],
            novaEntrega: novaEntrega,
            km: '',

        }
    }

    create = async values => {
        values.data = getFormatDataToDataApi(values.data);
        var retorno = await post(values);
        if (retorno.result === 'ok') {
            var paradasIdsTransferir = new URLSearchParams(this.props.location.search).get('paradasIdsTransferir');
            var resultadoMsg = 'Rota criada com sucesso!';
            if (paradasIdsTransferir) {
                var retornoTransferir = await transferirParada(retorno.data.id, paradasIdsTransferir.split(','), []);

                if (!retornoTransferir) {
                    this.props.openSnackbar('Erro ao transferir as paradas para a nova rota! Obs.: Rota criada.', 'error');
                    this.props.history.replace(RotasEditRoute + retorno.data.id);
                    return;
                }

                if (retornoTransferir.result === 'ok') {
                    resultadoMsg = 'Rota criada, e paradas transferidas com sucesso!';
                }
                else {
                    if (retornoTransferir.result === 'erro') {
                        this.props.openSnackbar('Erro ao transferir: ' + retornoTransferir.data, 'error');
                        this.props.history.replace(RotasEditRoute + retorno.data.id);
                        return;
                    } else {
                        this.props.openSnackbar('Erro ao transferir as paradas para a nova rota! Obs.: Rota criada.');
                        this.props.history.replace(RotasEditRoute + retorno.data.id);
                        return;
                    }
                }
            }

            this.props.openSnackbar(resultadoMsg);
            this.props.history.replace(RotasEditRoute + retorno.data.id);
        } else {
            this.props.openSnackbar('Erro ao criar a rota! Cadastro não criado.', 'error');
            throw new Error();
        }
    }

    update = async (values) => {
        const minValue = new Date(0); // Unix Epoch (01/01/1970 00:00 UTC)

        values.data = getFormatDataToDataApi(values.data);

        if (values.dataInicio <= minValue) {
            values.dataInicio = null;
        } else {
            values.dataInicio = getFormatDataToDataApi(values.dataInicio);
        }

        if (values.dataFim <= minValue) {
            values.dataFim = null;
        } else {
            values.dataFim = getFormatDataToDataApi(values.dataFim);
        }
 
        if (values.n_latitude) {
            values.raio = values.n_raio;
            values.longitude = values.n_longitude;
            values.latitude = values.n_latitude
        }

        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.props.openSnackbar('Rota atualizada com sucesso!');
        } else {
            this.props.openSnackbar('Erro ao atualizar a rota! Cadastro não atualizado.', 'error');
            throw new Error();
        }
    }

    changeStatus = (s) => {
        this.setState();
        this.setState({ loading: true });
        var c = this.state.values;
        c.statusRoteirizacao = s;
        this.setState({ statusRoteirizacao: s, values: c, openImportador: false });
        this.setState({ loading: false });
    }

    changeVeiculo = value => {
        return this.changeVeiculoById(value);
    }

    changeVeiculoById = async id => {
        var retorno = await getVeiculoById(id);
        if (retorno !== undefined) {
            var dataInicio = this.state.values.data;

            if (retorno.inicioRota) {
                dataInicio = dataInicio.split('T')[0] + "T" + retorno.inicioRota.split('T')[1];
            }

            var camposRetorno = [];
            if (retorno.motoristaId != null)
                camposRetorno.push({ campo: 'motoristaId', value: retorno.motoristaId });
            if (retorno.inicioRota)
                camposRetorno.push({ campo: 'data', value: dataInicio });
            if (retorno.localInicioId != null)
                camposRetorno.push({ campo: 'localInicioId', value: retorno.localInicioId });
            if (retorno.localFimId != null)
                camposRetorno.push({ campo: 'localFimId', value: retorno.localFimId });

            return {
                campos: camposRetorno
            };
        }
    }

    handleCloseImportador = () => {
        if (this.state.docNfs.find(x => { return x.situacao === 'Importando' }) ?? [].length > 0) {
            this.props.openSnackbar('Aguarde todos os arquivos serem importandos.', 'warning');
            return;
        }
        this.setState({ openImportador: false });
    };

    handleCloseAdicionarNF = () => {
        const { setOpenAdicionarNF } = this.context;

        if (this.context.isLoading) {
            this.props.openSnackbar('Aguarde.', 'warning');
            return;
        }

        setOpenAdicionarNF(false);
        this.componentDidMount(); // Precisa para dar reload na tela e aparaecer o erro de endereço.
    }

    async componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            getByIdTelaRota(id).then(async (value) => {
                if (value !== undefined) {
                    this.setState({ values: value, statusRoteirizacao: value.statusRoteirizacao });
                }
                var empresa = await getByIdEmpresa(getUser().empresaId);

                this.setState({ loading: false, adicionarParadaDuranteRota: empresa.adicionarParadaDuranteRota });
            }).catch((e) => {
                //throw new Error('Registro não encontrado!');
            });
        } else {
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    data: getFormatDataToDataApi(new Date()),
                    empresaId: getUser().empresaId,
                    status: 1,
                    dataCadastro: getFormatDataToDataApi(new Date())
                }
            }));

            var empresa = await getByIdEmpresa(getUser().empresaId);
            if (empresa) {
                if (empresa.localInicioFimId && empresa.localInicioFimId != null) {
                    this.setState(prevState => ({
                        values: {
                            ...prevState.values,
                            localInicioId: empresa.localInicioFimId,
                            localFimId: empresa.localInicioFimId
                        }
                    }));
                }
                if (empresa.inicioRota && empresa.inicioRota != null) {
                    this.setState(prevState => ({
                        values: {
                            ...prevState.values,
                            data: this.state.values.data.split('T')[0] + "T" + empresa.inicioRota.split('T')[1]
                        }
                    }));
                }
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        adicionarParadaDuranteRota: empresa.adicionarParadaDuranteRota
                    }
                }));
            }
        }

        var retornoApi = await axios.all([getVeiculos(true)])
            .then(axios.spread((veiculo) => {
                return {
                    veiculo
                };
            }));

        var campos = camposForm;

        var camposChange = [
            { name: 'veiculo', fun: (option) => { return option.placa + (option.apelido ? ' (' + option.apelido + ')' : '') } }
        ];

        camposChange.forEach(campo => {
            var field = campos.find(e => e.name === campo.name + "Id");

            if (retornoApi[campo.name]) {
                field.options = retornoApi[campo.name].map(option => {
                    return {
                        value: option.id,
                        label: campo.fun(option)
                    }
                })
            }
        });

        var veiculo = campos.find(e => e.name === "veiculoId");
        veiculo.onChange = this.changeVeiculo;

        var utilizaCadastroRotaRegiao = getEmpresa().utilizaCadastroRotaRegiao;
        if (!utilizaCadastroRotaRegiao) {
            var rotaRegiao = campos.find(e => e.name === "rotaRegiaoId");
            rotaRegiao.size = 0; // Deixa invisivel

            var motorista = campos.find(e => e.name === "motoristaId");
            veiculo.size = 3;
            motorista.size = 3;
        }

        if (getUser().empresaId === 56 || getUser().empresaId === 69)
            {
            var idExternoCampo = campos.find(e => e.name === "idExterno");
            idExternoCampo.size = 2; // Deixa visivel

            var emBrancoCampo = campos.find(e => e.name === "emBranco");
            emBrancoCampo.size = 2;
        }

        this.setState({ loadingField: false });
        this.setState({ camposForm: campos });

        this.divRef = React.createRef();

        var query = new URLSearchParams(this.props.location.search);
        var tab = query.get('tab');
        if (tab === 'mapa') {
            this.setState({ tabIndex: TabIndexForm.Mapa });
            this.divRef.current?.alterarTab(this.state.tabIndex);
        }
    }

    render() {
        const { openAdicionarNF, setOpenAdicionarNF } = this.context;

        var b = this.state.camposForm.find(e => e.name === "buttonStatusRoteirizacao");
        var alterarStatusRota = this.state.camposForm.find(e => e.name === "status");

        var dataInicioField = this.state.camposForm.find(e => e.name === "dataInicio");
        var dataFimField = this.state.camposForm.find(e => e.name === "dataFim");

        if ((getUser() !== undefined && ['RLuOKTh1Ko'].indexOf(getUser().role) > -1) && this.props.match.params.id) {
            dataInicioField.disabled = false;
            dataFimField.disabled = false;
        } else {
            dataInicioField.disabled = true;
            dataFimField.disabled = true;
        }

        if ((getUser() !== undefined && ['RLuOKTh1Ko'].indexOf(getUser().role) > -1) && this.props.match.params.id) {
            b.component = <ButtonRoteirizar routeId={this.props.match.params.id} statusRoteirizacao={this.state.statusRoteirizacao} changeStatus={this.changeStatus}></ButtonRoteirizar>;
            alterarStatusRota.disabled = false;
            alterarStatusRota.options = alterarStatusRota.options.filter(e => e.value !== 5);
        } else if ((getUser() !== undefined && ['Master'].indexOf(getUser().role) > -1)) {
            alterarStatusRota.disabled = false;
            alterarStatusRota.options = alterarStatusRota.options.filter(e => e.value !== 5);
        } else {
            b.component = null;
            alterarStatusRota.disabled = true;
        }

        var emBranco = this.state.camposForm.find(e => e.name === "emBranco");
        emBranco.component = <div></div>;

        var pesoTotal = this.state.camposForm.find(e => e.name === "pesoTotal");
        pesoTotal.component = <CapacidadeRota_Form values={this.state.values} isPeso={true} label={pesoTotal.label} />;

        var cubagemTotal = this.state.camposForm.find(e => e.name === "cubagemTotal");
        cubagemTotal.component = <CapacidadeRota_Form values={this.state.values} isCubagem={true} label={cubagemTotal.label} />;

        var kmPrevisto = this.state.camposForm.find(e => e.name === "kmPrevisto");
        kmPrevisto.component = <QuilometragemPrevista_Form value={this.state.values} />

        var volumeTotal = this.state.camposForm.find(e => e.name === "volumeTotal");
        if (this.state.values.volumeTotal === 1) {
            volumeTotal.label = 'Volume';
        } else {
            volumeTotal.label = 'Volumes';
        }
        volumeTotal.component = <FormatacaoDiferente_Form value={this.state.values.volumeTotal} label={volumeTotal.label} />

        var valorCarga = this.state.camposForm.find(e => e.name === "valorTotalCarga");
        valorCarga.component = <CapacidadeRota_Form values={this.state.values} isValorCarga={true} label={valorCarga.label} />

        var valorFrete = this.state.camposForm.find(e => e.name === "valorTotalFrete");
        valorFrete.component = <FormatacaoDiferente_Form value={this.state.values.valorTotalFrete} label={valorFrete.label} />

        var qtdParadas = this.state.camposForm.find(e => e.name === "quantidadeDeParadas");
        qtdParadas.component = <FormatacaoDiferente_Form value={this.state.values.quantidadeDeParadas} label={qtdParadas.label} />

        let id = this.props.match.params.id;

        if (id) {
            var desabilitado = true;

            if (!(this.state.values.status >= 2 && this.state.values.status <= 5))//em rota e finalizado
            {
                desabilitado = false;
            }
            if (desabilitado && this.state.adicionarParadaDuranteRota > 0) //Pode add durante a rota
                desabilitado = false;

            var tiposParadasPlanejadas = getEmpresa().tiposParadasPlanejadas;
            var novaParada = true;

            if (tiposParadasPlanejadas == null)
                novaParada = false;
            if (tiposParadasPlanejadas === '')
                novaParada = false;

            return (
                <TabHorizontal
                    value={this.state.tabIndex ?? 0}
                    ref={this.divRef}
                    abas={[
                        <Tab label="Rota" index={TabIndexForm.Rota} />,
                        <Tab label="Entregas" index={TabIndexForm.Entregas} />,
                        <Tab label="Evidências" index={TabIndexForm.Evidencias} />,
                        <Tab label="Mapa" index={TabIndexForm.Mapa} />,
                        (getEmpresa().registroQuilometragemObrigatorio && ([Roles.Suporte, Roles.Master, Roles.Logistica].includes(getUser().role))) && (
                            <Tab label="Registro Quilometragem" index={TabIndexForm.RegistroQuilometragem} />
                        )
                    ]}>
                    <TabPanel index={TabIndexForm.Rota}>
                        {(this.state.values.statusRoteirizacao === 4 ?
                            <Container style={{ with: '100%', marginTop: '20px' }} maxWidth='true'>
                                <Alert severity="error" >
                                    <AlertTitle>Erro ao roteirizar</AlertTitle>
                                    Verificar as paradas planejadas, provavelmente o roteirizador não encontrou algum endereço, e será necessário ajustar.<br />
                                    Após ajustar o endereço, clicar no botão <strong>ROTEIRIZAR</strong> para roteirizar novamente. <br />
                                    Se todos os endereços estiverem corretos e o erro persistir, entrar em contato com suporte da Grafos Tech.
                                </Alert>
                                <Grid container spacing={2} style={{ marginTop: '11px' }} >
                                    <Grid item xs={4} md={2} >
                                        <ButtonRoteirizar routeId={this.props.match.params.id} statusRoteirizacao={this.state.statusRoteirizacao} changeStatus={this.changeStatus}></ButtonRoteirizar>
                                    </Grid></Grid>
                            </Container> : <></>)}

                        {this.state.values.areasGeograficas != null && this.state.values.areasGeograficas.map((item, index) => (
                            <Container style={{ with: '100%', marginTop: '20px' }} maxWidth='true'>
                                <Alert severity={item.severity}>
                                    <AlertTitle>{item.nome}</AlertTitle>
                                    <div dangerouslySetInnerHTML={{ __html: item.descricao }} />
                                </Alert>
                            </Container>
                        ))}

                        <Form title="Editar rota" form="editRota" fields={this.state.camposForm} onSubmit={this.update} CanBack={false} CanOnlyBack={getUser().role === Roles.Monitoramento} initialValues={this.state.values} loading={this.state.loading || this.state.loadingField}
                            actions={(
                                <>
                                    <Box sx={{ display: "flex" }} >
                                        <ButtonComentarioRota rota={this.state.values} />
                                        {getUser().role === Roles.Monitoramento ? <></> : <ButtonImprimir ids={[id]} />}
                                    </Box>
                                </>
                            )} />

                        <Container style={{ with: '100%', marginTop: '20px' }} maxWidth='true'>
                            <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Suporte, Roles.Logistica]}>
                                <Typography variant="h5" id="tableTitle" component="div" style={{ fontWeight: 'bold' }}>
                                    Planejamento da rota: Edição de paradas
                                </Typography>
                                <Grid container spacing={2} style={{ marginTop: '11px' }} >
                                    {novaParada && this.state.novaEntrega ?
                                        <Grid item xs={4} md={2} >
                                            <Tooltip title="Criar uma parada e adicionar na rota">
                                                <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{ height: '100%' }} startIcon={<Entregaicon />} onClick={() => { this.props.history.push(ParadaPlanejadaNewRoute + '?rotaId=' + id); }}>
                                                    Nova parada
                                                </Button>
                                            </Tooltip>
                                        </Grid> : <></>}
                                    {this.state.novaEntrega && !novaParada ?
                                        <Grid item xs={4} md={2} >
                                            <Tooltip title="Criar uma entrega e adicionar na rota">
                                                <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{ height: '100%' }} startIcon={<Entregaicon />} onClick={() => { this.props.history.push(EntregasNewRoute2 + '?rotaId=' + id); }}>
                                                    Nova entrega
                                                </Button>
                                            </Tooltip>
                                        </Grid> : <></>}
                                    <Grid item xs={4} md={2}>
                                        <Tooltip title="Selecionar uma entrega já criada">
                                            <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<EntregaListaicon />} onClick={() => { this.props.history.push(RotasSelectEntregasRoute + id); }}>
                                                Selecionar entrega
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    {/* <Grid item xs={4} md={2}>
                                            <Tooltip title="Selecionar uma entrega já criada pelo mapa">
                                                <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<EntregaMapaIcon />} onClick={() => { this.props.history.push(RotasSelectEntregasComMapaRoute + id); }}>
                                                    Selecionar entrega pelo mapa
                                                </Button>
                                            </Tooltip>
                                        </Grid> */}
                                    <Grid item xs={4} md={2}>
                                        <Tooltip title="Transferir paradas pelo pelo mapa">
                                            <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<EntregaMapaIcon />} onClick={() => { this.props.history.push(RotasTransfeirComMapaRoute + id); }}>
                                                Transferir pelo mapa
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        <Tooltip title="Adicionar um local planejado na rota">
                                            <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<SvgIcon component={localPlanejado_3} />} onClick={() => { this.props.history.push(RotasSelectLocaisPlanejadosRoute + id); }}>
                                                Selecionar local planejado
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        <Tooltip title="Importar Notas Fiscais na rota">
                                            <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<SvgIcon component={notaFiscalIcon} />} onClick={() => { this.setState({ openImportador: true }); }}>
                                                Importar XML / planilha
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        <Tooltip title="Importar Notas Fiscais na rota">
                                            <Button type="submit" disabled={desabilitado} variant="outlined" color="primary" style={{}} startIcon={<SvgIcon component={barcode} />} onClick={() => { setOpenAdicionarNF(true); }}>
                                                Adição rápida (bip)
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Dialog open={this.state.openImportador} onClose={this.handleCloseImportador} aria-labelledby="form-dialog-title" maxWidth={'xl'}>
                                    <DialogContent>
                                        <Importador rotaId={id} ></Importador>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseImportador} color="primary">
                                            Fechar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog open={openAdicionarNF} onClose={this.handleCloseAdicionarNF} fullWidth={true} maxWidth={"xl"}>
                                    <div style={{ height: 700 }}>
                                        <DialogContent style={{ height: 700 }}>
                                            <RotaAdicionarNF rotaId={id}></RotaAdicionarNF>
                                        </DialogContent>

                                        <DialogActions style={{ position: "absolute", bottom: 0, right: 0 }}>
                                            <Button onClick={this.handleCloseAdicionarNF} color="primary">
                                                Fechar
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </Dialog>

                                <SequenciaFixadaContext.Consumer>
                                    {value => (
                                        <DialogDraggableTable
                                            open={value.open}
                                            rows={(function () {
                                                const paradasOrdenadas = value.paradasPlanejadas.sort((a, b) => {
                                                    // prioriza as paradas com situacaoParada === 1
                                                    if (a.situacaoParada === 1 && b.situacaoParada !== 1) {
                                                        return -1; // a vem antes de b
                                                    }
                                                    if (a.situacaoParada !== 1 && b.situacaoParada === 1) {
                                                        return 1; // b vem antes de a
                                                    }

                                                    // se ambos têm a mesma situação, ordena por data
                                                    const aDate = new Date(a.dataRealizada).getTime();
                                                    const bDate = new Date(b.dataRealizada).getTime();

                                                    // se as datas forem iguais, não altera a ordem
                                                    if (aDate === bDate) {
                                                        return 0;
                                                    }

                                                    if (a.situacaoParada === 0 && b.situacaoParada === 0) {
                                                        return a.sequencia - b.sequencia;
                                                    }

                                                    // ordena do mais recente para o mais antigo)
                                                    return bDate - aDate; // bDate primeiro para que a data maior venha primeiro
                                                });

                                                return paradasOrdenadas.map((element) => ({
                                                    ...element,
                                                    canDrag: element.situacaoParada !== 1,
                                                }));
                                            })()}
                                            onClose={() => value.setOpen(false)}
                                            onConfirm={(rows) => {
                                                value.setLoading(true);
                                                const updatedRows = rows.map((element) => ({
                                                    ...element,
                                                    sequenciaFixadaEm: element.index
                                                }));

                                                updateSequenciaFixada(id, updatedRows)
                                                    .then(resp => {
                                                        this.props.openSnackbar(resp.message);
                                                        value.setOpen(false)
                                                    }).catch(err => {
                                                        this.props.openSnackbar(err.message, "error");
                                                    }).finally(f => {
                                                        value.setLoading(false);
                                                    });
                                            }}
                                        />
                                    )}
                                </SequenciaFixadaContext.Consumer>
                            </ControleDeAcesso>
                            <div style={{ marginTop: '11px' }}>
                                <GridList
                                    filter={{ rotaId: id }}
                                    statusRoteirizacao={this.state.values.statusRoteirizacao}
                                    podeRemover={!desabilitado}
                                    desabilitado={desabilitado}
                                />
                            </div>
                        </Container>
                    </TabPanel>
                    <TabPanel index={TabIndexForm.Entregas}>
                        <EntregasGrid filter={{ rotaId: id }} />
                    </TabPanel>
                    <TabPanel index={TabIndexForm.Evidencias}>
                        <EvidenciasGrid filter={{ rotaId: id }} />
                    </TabPanel>
                    <TabPanel index={TabIndexForm.Mapa}>
                        <RotaMapa
                            rotaId={id}
                            veiculoId={this.state.values.veiculoId}
                            motoristaId={this.state.values.motoristaId}
                            dataInicio={this.state.values.dataInicio}
                            dataFim={this.state.values.dataFim}
                            localInicioId={this.state.values.localInicioId}
                            localFimId={this.state.values.localFimId}>
                        </RotaMapa>
                    </TabPanel>
                    <TabPanel index={TabIndexForm.RegistroQuilometragem}>
                        <RegistroQuilometragemGrid rotaId={id} />
                    </TabPanel>
                </TabHorizontal>
            );
        } else {
            return (
                <Form
                    title="Nova Rota"
                    form="NewRota"
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    CanBack={false}
                    initialValues={this.state.values}
                    loading={this.state.loadingField}
                />
            );
        }
    }
}

const FormConfigWithRouter = withRouter(FormConfig)

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfigWithRouter openSnackbar={openSnackbar} {...this.props} >
                    </FormConfigWithRouter>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;

