import { instance } from "./instance/axios";

export async function getMotivoByParadaPlanejadaId(id) {
    try {
        const response = await instance.get(`/api/Motivo/GetByParadaPlanejadaId/${id}`);

        if (response.status === 200) {
            return response.data;
        }
        
        return null;
    } catch (error) {
        return error.response.data;
    }
}