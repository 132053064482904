import { instance } from './instance/axios'

export async function getAll(filtroAtivo = null) {
  try {
    let query = ""
    if (filtroAtivo !== -1) {
      query = `?filtroAtivo=${filtroAtivo}`;
    }

    const response = await instance.get(`/api/RotaRegioes${query}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function post(registro) {
  try { 

    const response = await instance.post('/api/RotaRegioes', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function put(id, registro) {
  try {

    const response = await instance.put('/api/RotaRegioes/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/RotaRegioes/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
