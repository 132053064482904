import { instance } from "./instance/axios";

export async function getAll(filtro) {
    try {
        const response = await instance
            .get(`/api/RegistroQuilometragem?maxRegistros=${filtro.maxRegistros}&page=${filtro.page}`);
        
            if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getByRotaId(rotaId) {
    try {
        const response = await instance
            .get(`/api/RegistroQuilometragem/GetByRotaId/${rotaId}`);
        
            if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}