import {instance} from './instance/axios'

export async function getAll() {
    try {
      const response = await instance.get('/api/Motoristas');
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  export async function getById(id) {
    try {
      const response = await instance.get('/api/Motoristas/lookup?id='+id);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }