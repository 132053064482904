import { FieldType } from "../../Components/Form/common/FieldType";
import { tipoCombustivel } from "../../Enums/TipoCombustivel";
import { getFormatDataTimeToString } from "../../Utils/FormatDateTime";

export const columnsGrid = [
    { label: "Data", field: "data", type: FieldType.DATETIME },
    { label: "Veículo", field: "placa", type: "customWithRow", function: getVeiculo },
    { label: "Local do abastecimento", field: "descricaoLocal", type: "text" },
    { label: "Tipo combustível", field: "tipoCombustivel", type: "customWithRow", function: getTipoCombustivel },
    { label: "Qtd. abastecida", field: "quantidadeAbastecida", type: "customWithRow", function: getQuantidadeAbastecida, align: "right" },
    { label: "Valor unitário", field: "valorPorUnidade", type: FieldType.MONEY, align: "right" },
    { label: "Valor total", field: "valorTotal", type: FieldType.MONEY, align: "right" },
    { label: "Km inicial", field: "kilometragemInicialVeiculo", type: "number", align: "right" },
    { label: "Km final", field: "kilometragemVeiculo", type: "number", align: "right" },
    { label: "Km percorrida", field: "kilometragemPercorrida", type: "number", align: "right", style: { backgroundColor: '#f5f5f5' } },
    { label: "Média", field: "mediaKmPorUnidade", type: "customWithRow", function: getMedia, align: "right", style: { backgroundColor: '#f5f5f5' } },
    { label: "Valor por Km", field: "valorPorKm", type: FieldType.MONEY, align: "right", style: { backgroundColor: '#f5f5f5' } }
]

/**
 * @type {import("@mui/x-data-grid").GridColDef[]}
 */
export const columnsGridV2 = [
    {
        field: "data",
        headerName: "Data",
        description: "Data do abastecimento",
        flex: 1,
        valueFormatter: (value) => {
            return getFormatDataTimeToString(value);
        }
    },
    {
        field: "placa",
        headerName: "Veículo",
        description: "",
        flex: 1,
        valueFormatter: (value, row) => {
            return `${row.placa} ${row.apelido !== null && row.apelido !== "" ? `(${row.apelido})` : ""}`;
        }
    },
    {
        field: "descricaoLocal",
        headerName: "Local do abastecimento",
        description: "Local do abastecimento",
        flex: 1
    },
    {
        field: "tipoCombustivel",
        headerName: "Tipo combustível",
        description: "Tipo do combustível",
        flex: 1,
        valueFormatter: (value, row) => {
            return getTipoCombustivel(row);
        },
    },
    {
        field: "quantidadeAbastecida",
        headerName: "Qtd. abastecida",
        description: "Quantidade abastecida",
        type: "number",
        flex: 1,
        valueFormatter: (value, row) => {
            return getQuantidadeAbastecida(row);
        }
    },
    {
        field: "valorPorUnidade",
        headerName: "Valor unitário",
        description: "Valor unitário",
        flex: 1,
        type: "number",
        // valueFormatter: (value) => {
        //     return value != null ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ""
        // }

        renderCell: (params) => (
            <span style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <span>R$</span>
                <span>{params.value.toFixed(2)}</span>
            </span>
        ),
    },
    {
        field: "valorTotal",
        headerName: "Valor total",
        description: "Valor total",
        flex: 1,
        type: "number",
        valueFormatter: (value) => {
            return value != null ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ""
        }
    },
    {
        field: "kilometragemInicialVeiculo",
        headerName: "Km inicial",
        description: "Quilometragem inicial",
        flex: 1,
        type: "number"
    },
    {
        field: "kilometragemVeiculo",
        headerName: "Km final",
        description: "Quilometragem final",
        flex: 1,
        type: "number"
    },
    {
        field: "kilometragemPercorrida",
        headerName: "Km percorrida",
        description: "Quilometragem percorrida",
        flex: 1,
        type: "number"
    },
    {
        field: "mediaKmPorUnidade",
        headerName: "Média de consumo",
        description: "A média de consumo do veículo é calculada dividindo a distância percorrida pela quantidade de combustível abastecido.",
        flex: 1,
        type: "number",
        valueFormatter: (value, row) => {
            return getMedia(row);
        }
    },
    {
        field: "valorPorKm",
        headerName: "Valor por Km",
        description: "O Valor por Km é calculado dividindo o valor total do abastecimento pela distância percorrida.",
        flex: 1,
        type: "number",
        valueFormatter: (value) => {
            return value != null ? Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ""
        }
    }
]

export const camposForm = [
    { name: "kilometragemInicialVeiculo", label: "Quilometragem inicial do veículo", type: "number", size: 4, required: false, disabled: true },
    { name: "kilometragemVeiculo", label: "Quilometragem final do veículo", type: "number", size: 4, required: false, disabled: true },
    { name: "kilometragemPercorrida", label: "Quilometragem percorrida", type: "number", size: 4, required: false, disabled: true },

    { name: "quantidadeAbastecida", label: "Quantidade abastecida", type: "number", size: 4, required: false, disabled: true },
    { name: "valorPorUnidade", label: "Valor unitário", type: "money", size: 4, required: false, disabled: true },
    { name: "valorTotal", label: "Valor total", type: "money", size: 4, required: false, disabled: true },

    { name: "data", label: "Data", type: "datetime", size: 6, required: false, disabled: true },
    { name: "tipoCombustivel", label: "Tipo combustível", type: "text", size: 6, required: false, disabled: true },

    { name: "mediaKmPorUnidade", label: "Média Km por unidade", type: "number", size: 6, required: false, disabled: true },
    { name: "valorPorKm", label: "Valor por Km", type: "money", size: 6, required: false, disabled: true },

    { name: "informacaoComplementar", label: "Informação Complementar", type: "text", size: 12, required: false, disabled: true }
]

function getVeiculo(row) {
    return `${row.placa} ${row.apelido !== null && row.apelido !== "" ? `(${row.apelido})` : ""}`;
}

function getTipoCombustivel(row) {
    var tpCombustivel = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
            tpCombustivel = "Gasolina comum";
            break;
        case tipoCombustivel.GasolinaAditivada:
            tpCombustivel = "Gasolina aditivada";
            break;
        case tipoCombustivel.GasolinaPremium:
            tpCombustivel = "Gasolina premium";
            break;
        case tipoCombustivel.Etanol:
            tpCombustivel = "Etanol";
            break;
        case tipoCombustivel.GNV:
            tpCombustivel = "GNV";
            break;
        case tipoCombustivel.DieselS10:
            tpCombustivel = "Diesel S-10";
            break;
        case tipoCombustivel.DieselS500:
            tpCombustivel = "Diesel S-500";
            break;
        case tipoCombustivel.Biodiesel:
            tpCombustivel = "Biodiesel";
            break;
        case tipoCombustivel.Hidrogenio:
            tpCombustivel = "Hidrogênio";
            break;
        case tipoCombustivel.OVIN:
            tpCombustivel = "OVIN";
            break;
        case tipoCombustivel.Biometano:
            tpCombustivel = "Biometano";
            break;
        case tipoCombustivel.Arla:
            tpCombustivel = "Arla 32";
            break;
        case tipoCombustivel.Eletrico:
            tpCombustivel = "Elétrico";
            break;
        default:
            tpCombustivel = "";
            break;
    }

    return tpCombustivel;
}

function getQuantidadeAbastecida(row) {
    let sufixo = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
        case tipoCombustivel.GasolinaAditivada:
        case tipoCombustivel.GasolinaPremium:
        case tipoCombustivel.DieselS10:
        case tipoCombustivel.DieselS500:
        case tipoCombustivel.Etanol:
        case tipoCombustivel.Biodiesel:
        case tipoCombustivel.OVIN:
            sufixo = "litros";
            break;
        case tipoCombustivel.GNV:
        case tipoCombustivel.Biometano:
            sufixo = "M³"
            break;
        case tipoCombustivel.Hidrogenio:
            sufixo = "kg";
            break;
        case tipoCombustivel.Eletrico:
            sufixo = "kWh";
            break
        default:
            sufixo = "";
            break;
    }

    return `${row.quantidadeAbastecida} ${sufixo}`;
}

function getMedia(row) {
    let sufixo = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
        case tipoCombustivel.GasolinaAditivada:
        case tipoCombustivel.GasolinaPremium:
        case tipoCombustivel.DieselS10:
        case tipoCombustivel.DieselS500:
        case tipoCombustivel.Etanol:
        case tipoCombustivel.Biodiesel:
        case tipoCombustivel.OVIN:
            sufixo = "l";
            break;
        case tipoCombustivel.GNV:
        case tipoCombustivel.Biometano:
            sufixo = "m³"
            break;
        case tipoCombustivel.Hidrogenio:
            sufixo = "kg";
            break;
        case tipoCombustivel.Eletrico:
            sufixo = "kWh";
            break
        default:
            sufixo = "";
            break;
    }

    if (row.mediaKmPorUnidade == null)
        return "";

    return `${Number(row.mediaKmPorUnidade).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} Km/${sufixo}`;
}
